import { Settings } from '@/app'
import { Location, ShortCoords } from '@/types'
import axios from 'axios'

type LocationDetails = {
  plus_code: google.maps.places.PlacePlusCode
  results: google.maps.GeocoderResponse
  status: string
}

const transformGeolocationPosition = (pos: GeolocationPosition): Location => {
  const coords = pos.coords
  return {
    latitude: coords.latitude,
    longitude: coords.longitude,
    accuracy: coords.accuracy,
    speed: coords.speed,
    heading: coords.heading,
    altitude: coords.altitude,
  }
}

const getLocation = async (
  onSuccess: (position: GeolocationPosition) => void,
  canAskPermission = false,
) => {
  if (!navigator.geolocation) {
    console.error('Geolocation is not supported by this browser.')
    return
  }

  if (!canAskPermission) {
    logger.log('--- Cannot ask permission')
    return
  }

  navigator.geolocation.getCurrentPosition(
    (pos) => {
      logger.log('--- Got position successfully:', pos)
      onSuccess?.(pos)
    },
    (error) => {
      logger.log('--- Error getting location:', {
        code: error.code,
        message: error.message,
      })
    },
  )
}

const getIPInfo = async () => axios.get(Settings.Fetch.IPURL)

const getLocationDetails = async (coords: ShortCoords) => {
  return axios.get<LocationDetails>(`${Settings.Fetch.GeocodingURL}latlng=${coords.lat},${coords.lng}&key=${Settings.ApiCredentials.GoogleMaps.ApiKey}`)
}

const getCityName = (details: LocationDetails) => {
  let city = null
  const result = details.results?.[0]
  if (result) {
    city = result.address_components.filter(ac => (ac.types.includes('locality') || ac.types.includes('administrative_area_level_2')))?.[0].long_name
  }

  return city
}

export const LocationUtils = {
  getLocation,
  getIPInfo,
  getLocationDetails,
  getCityName,
  transformGeolocationPosition,
}
