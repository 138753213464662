import { COURSE_CARD_CONSTANTS } from '@/app/stylesheets/CourseCard'
import {
  CourseSection,
  CourseSectionProps,
  Text,
  Touchable,
  View,
} from '@/components'
import { CourseFilters } from '@/types'
import { Navigation, UserLocationUtils } from '@/utils'
import { useBreakpointMatch } from '@codeleap/web'

export const NearestSection = (props: Partial<CourseSectionProps>) => {

  const slidesToShow = useBreakpointMatch({
    mobile: null,
    tabletSmall: 2,
    laptop: 3,
    desktopLarge: 4,
    desktopHuge: 4,
  })

  const { location } = UserLocationUtils.useCurrentLocation()

  const city = location?.city || 'You'

  const sectionFilters: CourseFilters = {
    lat: location?.latitude,
    lng: location?.longitude,
  }

  const CARD_STYLE = COURSE_CARD_CONSTANTS.HIGH

  return (
    <CourseSection
      title={
        <View variants={['fullWidth', 'row', 'wrap']}>
          <Text
            variants={['h0', 'color:neutral9', 'bold']}
            responsiveVariants={{
              mobile: ['h2', 'color:neutral9', 'bold'],
            }}
            component='h2'
            text={`Local favourites near\u00A0`}
          />
          <Touchable
            debugName='nearest:naviation text'
            variants={['column', 'center']}
            style={{ display: 'inline-block' }}
            onPress={() => Navigation.Course.navigateToExplore({
              ...sectionFilters,
            })}
          >
            <Text
              text={city}
              variants={['h0', 'color:neutral9', 'bold', 'underline']}
              style={{ display: 'inline-block' }}
              responsiveVariants={{
                mobile: ['h2', 'color:neutral9', 'bold', 'underline'],
              }}
            />
          </Touchable>
        </View>
      }
      cardStyle={CARD_STYLE}
      sectionFilters={sectionFilters}
      slidesToShow={slidesToShow}
      courseCardProps={{
        variants: ['high'],
        onPress: (item) => Navigation.Course.navigateToDetails(item),
      }}
      showButton={false}
      {...props}
    />
  )
}

