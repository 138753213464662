import { View, List, SeeMoreButton } from '@/components'
import { PropsOf } from '@codeleap/common'
import React from 'react'

export type SeeMoreListProps = Omit<PropsOf<typeof List>, 'data' | 'placeholder'> & {
  wrapperProps?: PropsOf<typeof View>
  data: any[]
}

export const SeeMoreList = ({ fetchNextPage, wrapperProps, ...rest }: SeeMoreListProps) => {
  const hasNextPage = !!rest.hasNextPage && !rest?.isFetchingNextPage && !!rest.data?.length

  return (
    <View variants={['column', 'fullWidth', 'paddingBottom:2']} {...wrapperProps}>
      <List
        layoutWrapperProps={{
          component: 'ul',
        }}
        {...rest}
      />

      {!!hasNextPage && (
        <SeeMoreButton
          debugName='SeeMoreList:Button'
          onPress={fetchNextPage}
          variants={['margin:auto', 'marginTop:3']}
        />)}
    </View>
  )
}

