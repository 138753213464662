import { LeaderboardSortOptions } from '@/types'
import { SeeMoreList, SeeMoreListProps } from '../SeeMoreList'
import { LeaderboardRow } from './LeaderboardRow'
import { ListEmptyPlaceholder } from '../ListEmptyPlaceholder'
import { AppImages } from '@/app'
import { Skeleton } from '../Skeleton'

export type LeaderboardListProps = Omit<SeeMoreListProps, 'debugName' | 'renderItem'> & {
  sortBy: LeaderboardSortOptions
}

export const LeaderboardList = ({ sortBy, ...rest }: LeaderboardListProps) => {
  const renderItem = ({ item, index }) => {
    const isLast = index === rest?.data?.length - 1
    return (<LeaderboardRow item={item} position={index + 1} sortBy={sortBy} noSeparator={isLast} />)
  }

  return (
    <SeeMoreList
      debugName='LeaderboardList'
      renderItem={renderItem}
      {...rest}
      ListEmptyComponent={() => {
        if (rest?.isFetching) {
          return (
            <Skeleton
              columns={10}
              globalStyle={{
                width: '100%',
                height: 58,
              }}
            />
          )
        }

        return (
          <ListEmptyPlaceholder
            title={'It’s quiet here!'}
            description={'Get running and be the first on the leaderboard.'}
            image={AppImages.LeaderboardPlaceholder}
          />
        )
      }}
    />)
}
