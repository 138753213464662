import { SectionStyles } from '@/app/stylesheets'
import { SectionComposition } from '@/app/stylesheets/Section'
import {
  View,
  Text,
  CenterWrapper,
  Button,
  Skeleton,
  SkeletonProps,
} from '@/components'

import { ComponentVariants, PropsOf, StylesOf, TypeGuards, getNestedStylesByKey, useDefaultComponentStyle } from '@codeleap/common'
import React from 'react'

export type SectionHeaderProps = {
  title: string | JSX.Element
  description?: string
  buttonProps?: PropsOf<typeof Button>
  rightTxt?: string
  style?: PropsOf<typeof View>['style']
  showButton?: boolean
  variantStyles?: StylesOf<SectionComposition>
}

const SectionHeader = (props: SectionHeaderProps) => {
  const { title, description, buttonProps, rightTxt, showButton, variantStyles, ...rest } = props
  const buttonStyles = getNestedStylesByKey('headerButton', variantStyles)

  const Title = !TypeGuards.isString(title) ? (() => {
    return title
  }) : (() => {
    return (
      <Text
        text={title}
        css={variantStyles.headerTitle}
        responsiveVariants={{
          mobile: ['h5', 'color:neutral9', 'bold'],
        }}
        component='h2'
      />
    )
  })

  return (
    <View css={variantStyles.headerWrapper} component='header' {...rest}>
      <View
        css={variantStyles.headerInnerWrapper}
        responsiveVariants={{
          mobile: ['gap:1'],
        }}
      >
        <Title />
        {description ? (
          <Text
            text={description}
            css={variantStyles.headerDescription}
          />
        ) : null}
      </View>
      {showButton ? <Button styles={buttonStyles} {...buttonProps} /> : null}
    </View>
  )
}

type SectionProps = Omit<SectionHeaderProps, 'style'> & {

  children: React.ReactNode
  headerStyle?: SectionHeaderProps['style']
  centerContent?: boolean
  isLoading?: boolean
  wrapperProps?: PropsOf<typeof View>
  isVisible?: boolean
  skeletonProps?: SkeletonProps

} & ComponentVariants<typeof SectionStyles>

export const Section = (props: SectionProps) => {
  const {
    children,
    variants,
    headerStyle,
    centerContent = true,
    styles,
    isLoading,
    responsiveVariants,
    wrapperProps = {},
    isVisible = true,
    skeletonProps,
    ...headerProps
  } = props

  const variantStyles = useDefaultComponentStyle<
    'u:Section',
    typeof SectionStyles
  >('u:Section', {
    variants,
    styles,
    responsiveVariants,
  })

  const Wrapper = centerContent ? CenterWrapper : View

  const isReady = !isLoading

  if (!isVisible && isReady) return null

  return (
    <Wrapper {...wrapperProps} component='section' variants={variants} style={[variantStyles.wrapper, wrapperProps?.style]}>
      <SectionHeader style={headerStyle} variantStyles={variantStyles} {...headerProps} />
      {isReady ? children : <Skeleton {...skeletonProps} css={variantStyles.skeleton} />}
    </Wrapper>
  )
}
