import { APIClient } from '@/services'
import { Event } from '@/types'
import { View, Text, SeeMoreList, EventCommentRow, ListEmptyPlaceholder, Skeleton } from '@/components'
import { useFlatlistProps } from '@/utils'
import { AppImages } from '@/app'

export type EventCommentListProps = {
  eventID: Event['id']
}

export const EventCommentList = ({ eventID }: EventCommentListProps) => {
  const data = APIClient.Events.eventCommentsManager.use({
    filter: {
      group: eventID,
    },
    listOptions: {
      queryOptions: {
        enabled: !!eventID,
      },
    },
  })

  const listProps = useFlatlistProps(data)
  const totalComments = data?.list?.query?.data?.pages?.[0]?.count || ''

  const renderItem = ({ item, index }) => {
    const isLast = index === data.items.length - 1
    return (<EventCommentRow item={item} noSeparator={isLast} />)
  }

  return (
    <View variants={['column']}>
      <Text as='h2' variants={['h2', 'marginBottom:5']} text={`Comments (${totalComments})`} />
      {/*@ts-ignore*/}
      <SeeMoreList
        {...listProps}
        debugName='EventCommentList'
        renderItem={renderItem}
        ListEmptyComponent={() => {
          if (listProps?.isFetching) {
            return (
              <Skeleton
                columns={10}
                globalStyle={{
                  width: '100%',
                  height: 100,
                }}
              />
            )
          }

          return (
            <ListEmptyPlaceholder
              title={"There's no comments for now"}
              description={"There's no comments on this event yet. Be the first one to comment!"}
              image={AppImages.CommentsPlaceholder}
            />
          )
        }
        }
      />
    </View>
  )
}

const skeletonStyle = {
  width: '100%',
  height: 100,
}
