import { variantProvider } from '@/app'
import { Modal, View, Text, Button, RadioInput } from '@/components'
import { GenderType, LeaderboardFilters as LeaderboardFiltersType, LeaderboardSortOptions } from '@/types'
import { onUpdate, PropsOf, useState } from '@codeleap/common'

export type LeaderboardFiltersProps = PropsOf<typeof Modal> & {
  filters: LeaderboardFiltersType
  onApply: (filters: LeaderboardFiltersType) => void
  onClear?: () => void
  clearDisabled?: boolean
}

type SortByOption = {
  value: LeaderboardSortOptions
  label: string
}

const sortByOptions: SortByOption[] = [
  { value: 'grading', label: 'Highest Grading' },
  { value: 'total_distance', label: 'Total distance' },
  { value: 'num_runs', label: 'Number of runs' },
  { value: 'num_courses', label: 'Number of different courses' },
]

const genderOptions: GenderType[] = ['Male', 'Female', 'Non-Binary']

const ageGroupOptions = Array(14).fill('')

export const LeaderboardFilters = ({ filters, clearDisabled, onApply, onClear, toggle, ...rest }: LeaderboardFiltersProps) => {
  const [innerFilter, setInnerFilter] = useState<LeaderboardFiltersType>(filters)

  onUpdate(() => {
    setInnerFilter(filters)
  }, [filters])

  const handleApply = () => {
    toggle()
    onApply(innerFilter)
  }

  const handleClear = () => {
    toggle()
    onClear()
  }

  return (
    <Modal
      title='Filters'
      variants={['centered', 'titleLeft', 'scrollY']}
      styles={{
        body: styles.modalWrapper,
      }}
      toggle={toggle}
      {...rest}>
      <View variants={['fullWidth', 'column', 'flex']}>
        <RadioInput
          label='Sort by'
          debugName='LeaderboardFilters SortBy'
          variants={['options:vertical']}
          options={sortByOptions}
          onValueChange={(value: LeaderboardSortOptions) => {
            setInnerFilter(state => ({ ...state, sort_by: value }))
          }}
          value={innerFilter?.sort_by}
        />

        <Text text='Age group' variants={['marginBottom:1']} />
        <View css={styles.grid} variants={['marginBottom:3']} >
          {ageGroupOptions.map((_, index) => {
            const isLastRow = index >= ageGroupOptions.length - 1
            const minAge = 5 + index * 5
            const maxAge = minAge + 4

            const ageRange: Pick<LeaderboardFiltersType, 'birthday_min' | 'birthday_max'> = {
              birthday_max: getBirthDateFromAge(minAge),
              birthday_min: isLastRow ? '01-01-1875' : getBirthDateFromAge(maxAge),
            }

            const isSelected = ageRange.birthday_min === innerFilter?.birthday_min

            return (
              <Button
                debugName={'Age Range'}
                text={`${minAge}${isLastRow ? '+' : `-${maxAge}`}`}
                variants={['fitlerButton', isSelected ? 'fitlerButton:selected' : 'fitlerButton']}
                onPress={() => setInnerFilter(state => ({ ...state, ...ageRange }))}
              />
            )
          })}
        </View>

        <Text text='Gender' variants={['marginBottom:1']} />
        <View variants={['gap:1', 'wrap']}>
          {genderOptions.map(gender => {
            const isSelected = innerFilter?.gender === gender

            return (
              <Button
                text={gender}
                key={gender}
                debugName={gender}
                variants={['flex', isSelected ? 'fitlerButton:selected' : 'fitlerButton']}
                onPress={() => {
                  setInnerFilter(state => ({ ...state, gender }))
                }}
              />
            )
          })}
        </View>

        <View variants={['gap:1', 'marginTop:auto']}>
          <Button
            variants={['flex', 'large', 'neutral2']}
            debugName='LeaderboardFilters Clear'
            text='Clear'
            onPress={handleClear}
            disabled={clearDisabled}
          />
          <Button
            variants={['flex', 'large']}
            debugName='LeaderboardFilters Apply'
            text='Apply' onPress={handleApply}
          />
        </View>
      </View>
    </Modal>
  )
}

function getBirthDateFromAge(age: number) {
  if (typeof age !== 'number' || age < 0) {
    throw new Error('Invalid age. Please provide a valid positive number.')
  }

  const currentDate = new Date()
  const birthYear = currentDate.getFullYear() - age
  const birthMonth = String(currentDate.getMonth() + 1).padStart(2, '0')
  const birthDay = String(currentDate.getDate()).padStart(2, '0')

  return `${birthDay}-${birthMonth}-${birthYear}`
}

const styles = variantProvider.createComponentStyle((theme) => ({
  modalWrapper: {
    width: '100vw',
    height: '100vh',
    maxHeight: '70vh',
    maxWidth: 480,
    paddingTop: 0,
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, auto)',
    gap: theme.spacing.value(1),
  },
}), true)

