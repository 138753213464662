import { store, useAppSelector, UserLocationState } from '@/redux'

export type CurrentLocationReturn = {
  location: UserLocationState
}

const getLocation = () => store.getState().UserLocation.location

const useLocation = () => useAppSelector(state => state.UserLocation)

const getCurrentLocation = (): CurrentLocationReturn => {
  const { location, ipInfo, defaultLocation } = store.getState().UserLocation

  const _location = location || ipInfo || defaultLocation

  return {
    location: _location,
  }
}

const useCurrentLocation = (): CurrentLocationReturn => {
  const { location, ipInfo, defaultLocation } = useLocation()

  const _location = location || ipInfo || defaultLocation

  return {
    location: _location,
  }
}

export const UserLocationUtils = {
  getLocation,
  useLocation,
  useCurrentLocation,
  getCurrentLocation,
}
