import moment from 'moment'
import { useState } from 'react'
import { APIClient } from '@/services'
import { Event, GenderType, LeaderboardFilters as LeaderboardFiltersType, LeaderboardSortOptions } from '@/types'
import { formatDateToServer, getMounthName, useFlatlistProps } from '@/utils'
import {
  Button,
  Text,
  View,
  LeaderboardList,
  SegmentedControl,
  LeaderboardFilters,
  LeaderboardFiltersProps,
} from '@/components'
import { PropsOf } from '@emotion/react'
import { arePropsEqual, useBooleanToggle } from '@codeleap/common'
import { useSearchParams } from '@codeleap/web'
import { assignTextStyle, variantProvider } from '@/app'

const today = new Date()
const thisWeek = moment(today).startOf('week').toDate()
const mounth = new Date(today.getFullYear(), today.getMonth(), 1)

const tabs = [
  { label: `Today`, value: `today:${formatDateToServer(today)}` },
  { label: `This week`, value: `week:${formatDateToServer(thisWeek)}` },
  {
    label: getMounthName(mounth),
    value: `mounth:${formatDateToServer(mounth)}`,
  },
]

const defaultFilters: LeaderboardFiltersType = {
  sort_by: 'grading',
  gender: null,
  birthday_max: null,
  birthday_min: null,
}

const sortByData = [
  { title: 'Grade', value: 'grading' },
  { title: 'Distance', value: 'total_distance' },
  { title: 'Runs', value: 'num_runs' },
  { title: 'Courses', value: 'num_courses' },
]

export type EventLeaderboardProps = PropsOf<typeof View> & {
  eventID: Event['id']
}

export const EventLeaderboard = ({ eventID, ...rest }: EventLeaderboardProps) => {
  const [params, setParams] = useSearchParams()

  const time = params?.time || tabs[2].value

  const filters: LeaderboardFiltersType = {
    sort_by: (params?.sort_by || defaultFilters?.sort_by) as LeaderboardSortOptions,
    gender: (params?.gender || defaultFilters?.gender) as GenderType,
    birthday_max: params?.birthday_max || defaultFilters?.birthday_max,
    birthday_min: params?.birthday_min || defaultFilters?.birthday_min,
  }

  const setTime = (value: string) => {
    setParams(state => ({ ...state, time: value }))
  }

  const setFilters = (filters: LeaderboardFiltersType) => {
    setParams(state => ({ ...state, ...filters } as any))
  }

  const handleClear = () => {
    setParams(state => ({
      ...state,
      ...defaultFilters,
    }))
  }

  const data = APIClient.Leaderboard.leaderboardManager.use({
    filter: {
      time: time?.split(':')[1],
      group: eventID,
      ...filters,
    },
    listOptions: {
      queryOptions: {
        refetchOnWindowFocus: false,
        enabled: !!eventID,
      },
    },
  })

  const listProps = useFlatlistProps(data)

  return (
    <View variants={['column']} {...rest}>
      <LeaderboardHeader
        defaultFilters={defaultFilters}
        filters={filters}
        onApply={setFilters}
        clearDisabled={arePropsEqual(filters, defaultFilters, {
          check: [
            'sort_by',
            'gender',
            'birthday_max',
            'birthday_min',
          ],
        })}
        onClear={handleClear}
      />

      <SegmentedControl
        debugName={'Filter courses date'}
        options={tabs}
        value={tabs.find(tab => tab.value?.startsWith(time?.split(':')[0]))?.value}
        onValueChange={setTime}
        variants={['tab']}
        responsiveVariants={{
          mobile: ['fullWidth'],
        }}
      />

      <View variants={['gap:1', 'marginTop:2', 'marginBottom:3']}>
        {sortByData.map((item) => {
          const isSelected = filters.sort_by === item.value

          return (
            <Button
              key={item.value}
              debugName={`Sort by ${item.value}`}
              text={item.title}
              variants={['pill', isSelected ? 'secondary' : 'neutral2']}
              css={styles.tags}
              onPress={() => {
                setFilters({ sort_by: item?.value as LeaderboardSortOptions })
              }}
            />
          )
        })}
      </View>

      <LeaderboardList {...listProps} sortBy={filters?.sort_by} />

      <View variants={['thinSeparator', 'marginVertical:5']} />
    </View>
  )
}

const LeaderboardHeader = (props: LeaderboardFiltersProps) => {
  const [visible, toggle] = useBooleanToggle(false)

  return (
    <View css={styles.header}>
      <Text as='h2' css={styles.title}>Leaderboard</Text>
      <Button debugName='Event filters' text='Filters' variants={['link', 'minimal']} onPress={toggle} />
      <LeaderboardFilters visible={visible} toggle={toggle} {...props} />
    </View>
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  header: {
    gap: theme.spacing.value(2),
    ...theme.spacing.marginBottom(3),

    [theme.media.down('tabletSmall')]: {
      ...theme.presets.justifySpaceBetween,
    },
  },
  title: {
    ...assignTextStyle('h2')(theme).text,

    [theme.media.down('tabletSmall')]: {
      ...assignTextStyle('h3')(theme).text,
    },
  },

  tags: {
    [theme.media.down('tabletSmall')]: {
      ...theme.presets.flex,
    },
  },

}), true)
