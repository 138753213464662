import { OSAlert } from '@/app'
import { AppAuthErrors } from '@codeleap/common'

const errors = {
  'auth/wrong-password': 'Email or password is incorrect',
  'auth/not-registered': 'This user is not registered',
  'auth/requires-recent-login': 'You need to log in again to continue',
  'auth/invalid-login-credentials': 'Email or password is incorrect',
  'auth/too-many-requests': 'Access to this account has been temporarily disabled due to many failed attempts.',
  'auth/email-in-use': 'This email address is already taken',
  'auth/email-not-found': 'Could not find an account matching the specified email address',
  'auth/email-already-in-use': 'This email address is already taken',
  'auth/invalid-email': 'Email or password is incorrect', 
  'auth/user-disabled': 'Could not find an account with the specified email address and password', 
  'auth/user-not-found': 'Email or password is incorrect', 
  'auth/user-token-expired': null,
}

export const AuthErrors = new AppAuthErrors(errors, (err, module, args) => {
  const msg = err?.msg?.(args)

  if (!!err && !!msg) {
    OSAlert.error({ title: 'Error', body: msg })
  } else if (!err) {
    OSAlert.error({ title: 'Error', body: 'Something went wrong, please try again later' })
  }

  logger.error(`${module ?? 'Auth'} Error`, err, 'Auth')
})
