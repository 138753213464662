import { assignTextStyle } from './Text'
import { variantProvider } from '../theme'
import { ButtonComposition } from '@codeleap/common'

export type SectionComposition =
  | 'wrapper'
  | 'headerWrapper'
  | 'headerInnerWrapper'
  | 'headerTitle'
  | 'headerDescription'
  | `headerButton${Capitalize<ButtonComposition>}`
  | 'loaderWrapper'
  | 'loader'

const createSectionStyle =
  variantProvider.createVariantFactory<SectionComposition>()

export const SectionStyles = {
  default: createSectionStyle(theme => ({
    wrapper: {},
    headerWrapper: {
      ...theme.presets.row,
      ...theme.presets.alignCenter,
      ...theme.presets.justifySpaceBetween,
      marginBottom: theme.spacing.value(2),
      ...theme.spacing.gap(1),
    },
    skeleton: {
      [theme.media.down('mobile')]: {
        ...theme.spacing.marginLeft(2),
      },
    },
    headerInnerWrapper: {
      ...theme.presets.column,
      ...theme.spacing.gap(2),
    },
    headerTitle: {
      ...assignTextStyle('h0')(theme).text,
      color: theme.colors.neutral9,

      [theme.media.down('mobile')]: {
        ...assignTextStyle('h2')(theme).text,
        color: theme.colors.neutral9,
      },
    },
    headerDescription: {
      ...assignTextStyle('p1')(theme).text,
      color: theme.colors.neutral8,
    },
    loaderWrapper: {
      ...theme.presets.flex,
      ...theme.presets.center,
    },
    headerButtonWrapper: {
      backgroundColor: theme.colors.neutral1,
      ...theme.spacing.paddingHorizontal(0),

      '&:hover': {
        backgroundColor: theme.colors.neutral1,
      },
    },
    headerButtonText: {
      color: theme.colors.primary3,
      fontSize: theme.typography.base.styles.p1.size,
      fontWeight: '700',
      whiteSpace: 'nowrap',

      [theme.media.down('mobile')]: {
        fontSize: theme.typography.base.styles.p2.size,
        fontWeight: '400',
      },
    },
  })),
  'title:h2': createSectionStyle(theme => ({
    headerTitle: {
      ...assignTextStyle('h2')(theme).text,
    },
  })),
  areas: createSectionStyle(theme => ({
    headerTitle: {
      ...assignTextStyle('h0')(theme).text,
      color: theme.colors.neutral9,

      [theme.media.down('mobile')]: {
        ...assignTextStyle('h2')(theme).text,
        color: theme.colors.neutral9,
      },
    },
  })),
}
