import { variantProvider } from '@/app'
import { View, Text, Image } from '@/components'
import { PropsOf } from '@codeleap/common'

export type ListEmptyPlaceholderProps = PropsOf<typeof View> & {
  image?: string
  imageProps?: PropsOf<typeof Image>
  title: string
  description?: string
}

export const ListEmptyPlaceholder = ({ title, description, image, imageProps, ...rest }: ListEmptyPlaceholderProps) => {
  return (
    <View css={styles.wrapper} {...rest}>
      {!!image && (<Image source={image} css={styles.image} {...imageProps} />)}

      <Text text={title} variants={['h3', 'color:neutral9', 'marginBottom:1', 'textCenter']} />

      {!!description && (
        <Text text={description} variants={['color:neutral7', 'textCenter']} />
      )}
    </View>
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  wrapper: {
    ...theme.presets.flex,
    ...theme.presets.column,
    ...theme.presets.justifyCenter,
    ...theme.presets.alignCenter,
    ...theme.spacing.padding(5),
  },
  image: {
    maxWidth: '50%',
    marginBottom: theme.spacing.value(5),
    [theme.media.down('tabletSmall')]: {
      maxWidth: '90%',
    },

  },
}), true)
