import { useBreakpointMatch } from '@/app'
import { COURSE_CARD_CONSTANTS } from '@/app/stylesheets/CourseCard'
import {
  CourseSection, CourseSectionProps,
} from '@/components'
import { CourseFilters } from '@/types'
import { Navigation, UserLocationUtils } from '@/utils'

export const PersonalBestsSection = (props: Partial<CourseSectionProps>) => {

  const slidesToShow = useBreakpointMatch({
    mobile: null,
    tabletSmall: 2,
    laptop: 3,
    desktopLarge: 4,
    desktopHuge: 5,
  })

  const { location } = UserLocationUtils.useCurrentLocation()

  const sectionFilters: CourseFilters = {
    lat: location?.latitude,
    lng: location?.longitude,
    min_paved: 100,
    max_hilly: 0,
  }

  const CARD_STYLE = COURSE_CARD_CONSTANTS.SQUARE

  return (
    <CourseSection
      title={'Best runs for personal bests nearby'}
      cardStyle={CARD_STYLE}
      sectionFilters={sectionFilters}
      slidesToShow={slidesToShow}
      courseCardProps={{
        variants: ['square'],
        onPress: (item) => Navigation.Course.navigateToDetails(item),
      }}
      {...props}
    />
  )
}

