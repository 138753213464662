import { GetAppBannerComposition, GetAppBannerStyles } from '@/app/stylesheets'
import { AppImages, React, Settings } from '@/app'
import { View, Text, ActionIcon, Image, Button, CenterWrapper } from '@/components'
import { useDefaultComponentStyle, PropsOf, ComponentVariants, StylesOf, TypeGuards, useBooleanToggle } from '@codeleap/common'
import { Navigation } from '@/utils'

type ViewProps = PropsOf<typeof View>

type GetAppBannerProps = Omit<ViewProps, 'variants'> & ComponentVariants<typeof GetAppBannerStyles> & {
  styles?: StylesOf<GetAppBannerComposition>
  openAppText?: string
  showCloseButton?: boolean
}

const DEFAULT_OPEN_TEXT = 'Get app'

export const GetAppBanner = (props: GetAppBannerProps) => {

  const { responsiveVariants, variants, styles, openAppText, showCloseButton } = props

  const [appBanner, toggleAppBanner] = useBooleanToggle(true)

  const variantStyles = useDefaultComponentStyle<
    'u:GetAppBanner',
    typeof GetAppBannerStyles
  >('u:GetAppBanner', {
    variants,
    styles,
    responsiveVariants,
  })

  if (!appBanner) return null

  return (
    <CenterWrapper
      styles={{
        wrapper: variantStyles.wrapper,
        innerWrapper: variantStyles.innerWrapper,
      }}
    >
      <View css={variantStyles.leftWrapper}>
        {showCloseButton ? (
          <ActionIcon
            debugName={'Close App Banner Icon'}
            icon={'close'}
            onPress={toggleAppBanner}
            css={variantStyles.closeButton}
            styles={{ icon: variantStyles.closeButtonIcon }}
          />
        ) : null}
        <View css={variantStyles.logoWrapper} variants={['bg:primary1']}>
          <Image
            alt='Get the app! | Skamper'
            source={AppImages.SkamperLogo}
            css={variantStyles.logo} />
        </View>
        <View css={variantStyles.textsWrapper}>
          <Text text='Skamper' css={variantStyles.title} />
          <Text text='Open in the Skamper app' css={variantStyles.description} />
        </View>
      </View>
      <Button
        debugName={'Open App Button'}
        onPress={() => Navigation.General.navigateToStore()}
        text={`${openAppText ?? DEFAULT_OPEN_TEXT}`} css={variantStyles.getAppButton}
      />
    </CenterWrapper>

  )
}
