import { variantProvider } from '@/app'
import { View, Text, Icon, Image } from '@/components'
import { LeaderboardFilters, LeaderboardPosition, LeaderboardSortOptions } from '@/types'
import { LeaderboardUtils } from '@/utils'
import { PropsOf } from '@codeleap/common'

export type LeaderboardRowProps = PropsOf<typeof View> & {
  item: LeaderboardPosition
  position: number
  filters?: LeaderboardFilters
  sortBy: LeaderboardSortOptions
  noSeparator?: boolean
}

export const LeaderboardRow = ({
  item,
  position,
  filters,
  sortBy = 'grading',
  noSeparator = false,
  variants = [],
  ...rest
}: LeaderboardRowProps) => {

  const trophy = LeaderboardUtils.getTrophyByPosition(position)

  return (
    <View {...rest} variants={[...variants, !noSeparator && 'borderBottom']} css={[styles.wrapper]}>
      <View variants={['alignCenter', 'flex']}>
        {!!trophy ?
          <Image source={trophy} css={styles.trophy} />
          :
          <View css={styles.position}>
            <Text
              text={String(position)}
              variants={['p2', 'bold', 'primary-3']}
            />
          </View>
        }

        <View variants={['column', 'marginLeft:2']}>
          <Text
            as='p'
            className='truncate-1'
            variants={['h4']}
            text={item?.profile?.full_name}
            css={styles.userName}
          />
          <Text
            as='p'
            className='truncate-1'
            variants={['color:neutral9']}
            text={item?.course?.title}
            css={styles.courseName}
          />
        </View>
      </View>

      <View variants={['row', 'alignCenter', 'marginLeft:2']}>
        <Icon
          debugName='LeaderboardRow:Icon'
          {...LeaderboardUtils.getSorbyByIcon(sortBy)}
          style={styles.icon}
        />
        <Text
          variants={['color:neutral5', 'p4', 'marginLeft:0.5']}
          text={`${LeaderboardUtils.getSortByText(item[sortBy], sortBy)}`}
        />
      </View>
    </View>
  )
}

const POSITION_SIZE = 24
const ICON_SIZE = 16

const styles = variantProvider.createComponentStyle((theme) => ({
  wrapper: {
    ...theme.presets.alignCenter,
    ...theme.presets.justifySpaceBetween,
    ...theme.spacing.paddingVertical(1),
  },
  userName: {},
  courseName: {},
  trophy: {
    height: POSITION_SIZE,
    width: POSITION_SIZE,
  },
  position: {
    borderRadius: theme.borderRadius.rounded,
    minHeight: POSITION_SIZE,
    minWidth: POSITION_SIZE,
    paddingHorizontal: theme.spacing.value(0.5),
    ...theme.presets.justifyCenter,
    ...theme.presets.alignCenter,
    backgroundColor: theme.colors.secondary,
  },
  icon: {
    width: ICON_SIZE,
    height: ICON_SIZE,
  },
}), true)
