import { variantProvider } from '@/app'
import {
  View,
  Text,
  PlacesAutoComplete,
} from '@/components'
import { SearchPrediction } from '@/types'
import { Navigation, UserLocationUtils, useIsMobile } from '@/utils'
import { useSearchParams } from '@codeleap/web'
import { StaticImage } from 'gatsby-plugin-image'

export const BannerSection = () => {
  const { location: userLocation } = UserLocationUtils.useCurrentLocation()
  const [params, setParams] = useSearchParams({ search: '' })
  const search = params?.search

  function setSearch(value: string) {
    setParams(state => ({ ...state, search: value }))
  }

  const isMobile = useIsMobile()

  const onItemPress = (place: SearchPrediction) => {
    Navigation.Course.navigateToExplore({
      search: place?.main_text,
      lat: place?.coords?.lat,
      lng: place?.coords?.lng,
    })
  }

  return (
    <View
      variants={['fullWidth', 'relative', 'center', 'padding:2']}
      style={styles.wrapper}
      component='section'
    >
      {isMobile ? (
        <View>
          {/* @ts-ignore */}
          <StaticImage
            src={'../../app/assets/images/Find-Your-Next-Run.webp'}
            alt='Find your next run | Skamper'
            style={styles.image}
            loading='eager'
            placeholder={'blurred'}
            blurredOptions={{
              toFormat: 'webp'
            }}
          />
        </View>
      ) : (
        <View>
          {/* @ts-ignore */}
          <StaticImage
            src={'../../app/assets/images/Find-Your-Next-Run-horizontal.png'}
            alt='Find your next run | Skamper'
            style={styles.image}
            loading='eager'
            placeholder={'blurred'}
            blurredOptions={{
              toFormat: 'webp'
            }}
          />
        </View>
      )}
      <View
        variants={['column', 'fullWidth', 'center', 'gap:4']}
        responsiveVariants={{
          mobile: ['gap:3'],
        }}
        style={styles.content}
        component='header'
      >
        <Text
          text='Find your next run'
          variants={['hx', 'color:neutral1', 'textCenter']}
          responsiveVariants={{
            mobile: ['h0', 'color:neutral1', 'textCenter'],
          }}
          component='h1'
          style={styles.bannerText}
        />

        <View
          variants={['fullWidth']}
          style={styles.searchBarWrapper}
        >
          <PlacesAutoComplete
            searchText={search}
            onChangeSearch={setSearch}
            onItemPress={onItemPress}
            origin={userLocation}
            variants={['mid']}
          />
        </View>
      </View>
    </View>
  )
}

const BANNER_HEIGHT = 400
const CONTENT_WIDTH = 665
const SEARCH_BAR_WIDTH = 500

const styles = variantProvider.createComponentStyle(theme => ({
  wrapper: {
    height: BANNER_HEIGHT,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    ...theme.presets.relative,
  },
  gradient: {
    ...theme.presets.absolute,
    ...theme.presets.fullHeight,
    ...theme.presets.fullWidth,
    background: '#00000040',
  },
  content: {
    maxWidth: CONTENT_WIDTH,
    zIndex: theme.zIndexes.bannerSection,
  },
  imageSectionWrapper: {
    height: 400,
  },
  image: {
    ...theme.presets.absolute,
    ...theme.presets.top,
    ...theme.presets.left,
    ...theme.presets.fullHeight,
    ...theme.presets.fullWidth,
  },
  searchBarWrapper: {
    zIndex: theme.zIndexes.placesAutocomplete,
    maxWidth: SEARCH_BAR_WIDTH,
  },
  bannerText: {
    fontSize: 75,
    lineHeight: '75px',

    [theme.media.down('tablet')]: {
      fontSize: 64,
      lineHeight: '64px',
    },

    [theme.media.down('mobile')]: {
      fontSize: 38,
      lineHeight: '38px',
    },
  },
}), true)
