/* eslint-disable max-len */
import { api } from '@/app'
import { Event } from '@/types'
import { QueryManager } from '@codeleap/common'
import { queryClient } from '../queryClient'

const BASE_URL = 'web_group/'

export async function retrieve(courseId: Event['id']) {
  const response = await api.get<Event>(`${BASE_URL}${courseId}/`)
  return response.data
}

export const eventsManager = new QueryManager<Event>({
  itemType: {} as Event,
  name: 'events',
  queryClient: queryClient.client,

  retrieveItem: async (id: Event['id']) => {
    const response = await retrieve(id)
    return response || null
  },
})

export * from './comments'
