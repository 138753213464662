import { Theme, variantProvider } from '@/app'
import { COURSE_CARD_CONSTANTS } from '@/app/stylesheets/CourseCard'
import { View, CourseCardProps, EmblaCarousel, CourseCard, EmblaCustomOptionsType } from '@/components'
import { Course } from '@/types'
import { useIsMobile, useRefDimensions } from '@/utils'
import { PropsOf, useCallback, useRef } from '@codeleap/common'

export type CourseCarouselProps = {
  courses: Course[]
  courseCardProps?: Partial<CourseCardProps>
  cardWrapperProps?: PropsOf<typeof View>
  itemWidth?: number
  roundSliders?: boolean
  smallCardWidth?: number
  desktopCardWidth?: number
  slidesToShow?: number
  responsiveWrapper?: boolean
  defaultCardWidth?: number
  carouselOptions?: EmblaCustomOptionsType
  isLoading?: boolean
} & PropsOf<typeof View>

export const CourseCarousel = (props: CourseCarouselProps) => {
  const {
    courses,
    courseCardProps,
    slidesToShow: _slidesToShow,
    roundSliders = false,
    smallCardWidth = COURSE_CARD_CONSTANTS.SMALL.WIDTH.MOBILE,
    defaultCardWidth = COURSE_CARD_CONSTANTS.DEFAULT.WIDTH.DEFAULT,
    itemWidth: _itemWidth,
    cardWrapperProps = {},
    carouselOptions,
    style,
    isLoading,
    ...wrapperProps
  } = props

  const sliderRef = useRef(null)
  const { refWidth: sliderWidth } = useRefDimensions(sliderRef)

  const isMobile = useIsMobile()
  const cardWidth = (isMobile ? smallCardWidth : defaultCardWidth)

  const itemWidth = _itemWidth ?? cardWidth
  const slidesToShow = _slidesToShow ?? (roundSliders ? Math.floor(sliderWidth / itemWidth) : (sliderWidth / itemWidth))

  const renderItem = useCallback((item) => {
    const {
      onPress,
      ...otherItemProps
    } = courseCardProps

    const handleItemPress = () => {
      courseCardProps.onPress?.(item)
    }

    return (
      <View
        variants={['fullWidth']}
        {...cardWrapperProps}
      >
        <CourseCard
          course={item}
          onPress={handleItemPress}
          {...otherItemProps}
        />
      </View>
    )
  }, [courseCardProps, cardWrapperProps])

  return (
    <View style={{ ...style, ...styles.wrapper }} {...wrapperProps}>
      <View variants={['fullWidth']} ref={sliderRef} />
      <EmblaCarousel
        items={courses}
        renderItem={({ item }) => renderItem(item)}
        isLoading={isLoading}
        skeletonProps={{
          rows: slidesToShow,
          mobileStyle: { height: COURSE_CARD_CONSTANTS.SQUARE.HEIGHT.MOBILE, minWidth: itemWidth },
          css: {
            paddingLeft: Theme.spacing.value(2),
          },
        }}
        options={{
          slidesToShow,
          fakeFirstPadding: isMobile,
          gap: isMobile ? Theme.spacing.value(1) : Theme.spacing.value(2),
          fixedWidth: isMobile ? cardWidth : null,
          slidesToScroll: 'auto',
          isLoading,
          ...carouselOptions,
        }}
      />
    </View>
  )
}

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    wrapper: {
      ...theme.presets.column,
    },
  }),
  true,
)
