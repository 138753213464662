import moment from 'moment'
import { Units } from './units'

export const HOUR_IN_SECONDS = 3600

const getMaxDate = (minAge: number) => {
  const now = new Date()
  const maxDate = new Date()
  maxDate.setFullYear(now.getFullYear() - minAge)
  return maxDate
}

const getMinDate = (maxAge: number) => {
  const now = new Date()
  const minDate = new Date()
  minDate.setFullYear(now.getFullYear() - maxAge)
  return minDate
}

export const formatSeconds = (
  seconds,
  withHours = false,
  withSeconds = true
) => {
  const hours = Units.convert({ from: 's', to: 'h', value: seconds })
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = seconds % 60
  const remainingMinutes = minutes % 60

  const hasHours = withHours ? true : hours > 0

  const result = withSeconds
    ? `${remainingMinutes.toString().padStart(2, '0')}:${remainingSeconds
        .toString()
        .padStart(2, '0')}`
    : `${remainingMinutes.toString().padStart(2, '0')}`

  return `${hasHours ? `${hours}:` : ''}${result}`
}

export const getAbreviatedDate = date => moment(date).format('DD MMM YYYY')

export const getBirthdayConstraints = (minAge = 16, maxAge = 100) => {
  const minDate = getMinDate(maxAge)
  const maxDate = getMaxDate(minAge)

  return {
    minDate,
    maxDate,
    startDate: minDate,
  }
}

export function secondsToISO8601(seconds: number) {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const remainingSeconds = seconds % 60

  let isoDuration = 'PT'
  if (hours > 0) isoDuration += `${hours}H`
  if (minutes > 0) isoDuration += `${minutes}M`
  if (remainingSeconds > 0) isoDuration += `${remainingSeconds}S`

  return isoDuration || 'PT0S'
}

export function formatDateToServer(date: Date) {
  if (!date) return
  const year = date.getFullYear().toString()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')

  return `${year}-${month}-${day}`
}

export function getMounthName(date: Date) {
  return date.toLocaleString('en-US', { month: 'long' })
}
