/* eslint-disable max-lines */
import { AppImages, MapDefaults, Settings, Theme } from '@/app'
import {
  DrawCircleProps,
  DrawComponentProps,
  DrawMapNumberPinProps,
  DrawMarkerProps,
  DrawPolylineProps,
  FitToCircleProps,
  FitToMarkersProps,
  MapCircle,
  MapInstance,
  MapMarker,
  MapPolyline,
  MapTileCoord,
  MapsInstance,
  ShortCoords,
  UseDrawCircleProps,
  UseDrawNavigationMarkerProps,
  UseRenderComponents,
} from '@/types'
import { UserLocationUtils } from '../UserLocation'
import { toShortCoords } from '../spatial'
import { renderToString } from 'react-dom/server'
import { StyleUtils } from '../styles'
import { JSXElementConstructor, ReactElement, useRef } from 'react'
import { MapNumberPin, NavigationMarker } from '@/components'
import { onUpdate } from '@codeleap/common'
import { areaPropsEquals } from '../data'
import { MapRedux } from '@/redux'
import { isMediaQuery } from '@codeleap/web'

// MAP DOCS -> https://developers.google.com/maps/documentation/javascript

const toMapLatLng = ({ maps, lat, lng }: { maps: MapsInstance } & ShortCoords) => {
  return new maps.LatLng(lat, lng)
}

const fitToCircle = ({ map, circle, paddings = 0 }: FitToCircleProps) => {
  // Fit map to circle bounds
  map.fitBounds(circle.getBounds(), {
    top: paddings,
    bottom: paddings,
    left: paddings,
    right: paddings,
  })
}

const fitToMarkers = ({
  map,
  maps,
  markers = [],
  paddings = 0,
  delay = 3000,
  zoom = null,
}: FitToMarkersProps) => {
  const bounds = new maps.LatLngBounds()
  markers.forEach((marker) => {
    bounds.extend(toMapLatLng({ maps, ...marker }))
  })

  map.fitBounds(
    bounds,
    {
      top: paddings,
      bottom: paddings,
      left: paddings,
      right: paddings,
    },
    delay,
  )

  const boundsZoom = getZoom(map)
  const adjustZoom = zoom > boundsZoom

  if (!!zoom && !adjustZoom) map?.setZoom(zoom)
}

const followUser = (props: Omit<FitToMarkersProps, 'markers'>) => {
  const location = UserLocationUtils.getCurrentLocation()
  fitToMarkers({ ...props, markers: [toShortCoords(location)], zoom: MapDefaults.zoom.followUser })
}

const drawPolyline = ({
  maps,
  map,
  path,
  strokeColor,
  strokeWeight = 5,
  borderColor,
  borderWith = 2,
  onPress,
  onBlur,
  onHover,
  ...rest }: DrawPolylineProps) => {
  if (!path || !maps || !map) return

  let border = null

  if (!!borderColor) {
    border = new maps.Polyline({
      path,
      geodesic: true,
      strokeColor: borderColor,
      strokeWeight: strokeWeight + borderWith,
      ...rest,
    })

    border.setMap(map)
  }

  const polyline = new maps.Polyline({
    path,
    geodesic: true,
    strokeColor: strokeColor || Theme.colors.light.primary3,
    strokeWeight,
    ...rest,
  })

  if (onPress) {
    polyline.addListener('click', (e) => {
      e.domEvent.stopPropagation()
      onPress?.(e, polyline)
    })
  }

  if (onHover) {
    polyline.addListener('mouseover', (e) => {
      onHover?.(e, polyline)
    })
  }

  if (onBlur) {
    polyline.addListener('mouseout', (e) => {
      onBlur?.(e, polyline)
    })
  }

  polyline.setMap(map)

  return { polyline, border }
}

const setPolylinesVisible = (polylines: MapPolyline[], visible: boolean) => {
  polylines.forEach((line) => {
    line.setVisible(visible)
  })
}

const removePolyline = (polyline: MapPolyline) => {
  polyline.setMap(null)
}

const removeMarker = (marker: MapMarker) => {
  marker.setMap(null)
}

const removeElement = (element) => {
  element.setMap(null)
}

const removeElements = (elements = []) => {
  elements.forEach(removeElement)
}

const drawMarker = ({
  maps,
  map,
  position,
  id,
  image = null,
  size = 32,
  onPress = () => { },
  onHover = () => { },
  onBlur = () => { },
  ...rest
}: DrawMarkerProps) => {
  const _image = image || AppImages.CourseStartingPoint

  const marker = new maps.Marker({
    position,
    map,
    icon: {
      url: _image,
      scaledSize: new maps.Size(size, size),
    },
    ...rest,
  })

  marker.addListener('click', (e) => {
    e.domEvent.stopPropagation()
    onPress(marker)
  })

  marker.addListener('mouseover', () => {
    onHover(marker)
  })

  marker.addListener('mouseout', () => {
    onBlur(marker)
  })

  if (id) marker.set('id', id)
  marker.setMap(map)

  return marker
}

const drawComponent = async ({
  maps,
  map,
  position,
  component,
  onPress,
  onBlur,
  onHover,
  ...rest
}: DrawComponentProps) => {
  const { AdvancedMarkerElement } = await maps.importLibrary('marker')

  const contentHtml = renderToString(component)
  const customElement = document.createElement('div')
  customElement.innerHTML = contentHtml

  const marker = new AdvancedMarkerElement({
    map,
    position,
    content: customElement,
    ...rest,
  })

  marker.addListener('click', (e) => {
    e.domEvent.stopPropagation()
    onPress?.(e)
  })

  marker.content.addEventListener('mouseenter', (e) => {
    onHover?.(e)
  })

  marker.content.addEventListener('mouseleave', (e) => {
    onBlur?.(e)
  })
  return marker
}

const drawDashedPolyline = ({ maps, map, path, ...rest }: DrawPolylineProps) => {
  const lineSymbol = {
    path: 'M 0,-1 0,1',
    strokeOpacity: 1,
    scale: 4,
  }

  const dashedLine = new maps.Polyline({
    path,
    geodesic: true,
    strokeColor: StyleUtils.opacity(Theme.colors.light.primary3, 35),
    strokeWeight: 8,
    strokeOpacity: 0,
    icons: [
      {
        icon: lineSymbol,
        offset: '0',
        repeat: `${Theme.spacing.value(2)}px`,
      },
    ],
    ...rest,
  })

  dashedLine.setMap(map)
  return dashedLine
}

const hideControls = (map: MapInstance) => {
  map.setOptions({
    disableDefaultUI: true,
  })
}

const toggleFullscreen = (id = 'google-maps' as string): void => {
  const isBelowTablet = isMediaQuery(Theme.media.down('tabletSmall'))

  if (isBelowTablet) {
    MapRedux.toggleFullscreen()
    return
  }

  const doc = document as any
  const isInFullscreen = doc.fullscreenElement || doc.webkitFullscreenElement || doc.mozFullScreenElement || doc.msFullscreenElement

  if (!isInFullscreen) {
    const element = document.getElementById(`${id}`)?.firstChild as HTMLElement | null
    if (element.requestFullscreen) {
      element.requestFullscreen()
    }
  } else {
    if (doc.exitFullscreen) {
      doc.exitFullscreen()
    } else if (doc.webkitExitFullscreen) {
      doc.webkitExitFullscreen()
    } else if (doc.mozCancelFullScreen) {
      doc.mozCancelFullScreen()
    } else if (doc.msExitFullscreen) {
      doc.msExitFullscreen()
    }
  }
}

const getZoomByRadius = (radius: number) => {
  const radiusZoomMap = {
    0.5: 15,
    5: 14,
    10: 11.5,
    25: 10.3,
    35: 10,
    45: 9.5,
    50: 9.4,
    100: 7,
    250: 6,
    500: 5,
    1000: 4,
  }

  const closestRadius = Object.keys(radiusZoomMap).reduce((a, b) => {
    return Math.abs(Number(b) - radius) < Math.abs(Number(a) - radius) ? b : a
  })

  return radiusZoomMap[closestRadius]
}

export const useMap = () => {
  const mapRef = useRef<MapsInstance>(null)
  const mapsRef = useRef<MapsInstance>(null)
  const stopPropagationRef = useRef(false)

  const _fitToMarkers = (props: Omit<FitToMarkersProps, | 'map' | 'maps'>) => {
    const mapProps = { map: mapRef.current, maps: mapsRef.current }
    fitToMarkers({ ...mapProps, ...props })
  }

  const _hideControls = () => {
    hideControls(mapRef.current)
  }
  const _followUser = (props: Omit<FitToMarkersProps, 'markers' | 'map' | 'maps'>) => {
    const mapProps = { map: mapRef.current, maps: mapsRef.current }
    followUser({ ...mapProps, ...props })
  }

  const mapRefs = { mapRef, mapsRef, stopPropagationRef }

  return {
    mapRefs,
    fitToMarkers: _fitToMarkers,
    hideControls: _hideControls,
    followUser: _followUser,
  }
}

function normalizeTileCoord(coord: MapTileCoord, zoom: number) {
  const y = coord.y
  let x = coord.x

  // tile range in one direction range is dependent on zoom level
  // 0 = 1 tile, 1 = 2 tiles, 2 = 4 tiles, 3 = 8 tiles, etc
  const tileRange = 1 << zoom

  // don't repeat across y-axis (vertically)
  if (y < 0 || y >= tileRange) {
    return null
  }

  // repeat across x-axis
  if (x < 0 || x >= tileRange) {
    x = ((x % tileRange) + tileRange) % tileRange
  }

  return { x: x, y: y }
}

const setThunderforestMapType = (map: MapInstance) => {
  const mapName = Settings.ApiCredentials.Thunderforest.name

  const thunderforestTiles = new google.maps.ImageMapType({
    getTileUrl: function (coord, zoom): string {
      const normalizedCoord = normalizeTileCoord(coord, zoom)

      if (!normalizedCoord) {
        return ''
      }

      const serverUrl = Settings.Fetch.Thunderforest
      const x = normalizedCoord.x
      const y = normalizedCoord.y
      const apiKey = Settings.ApiCredentials.Thunderforest.ApiKey

      const url = `${serverUrl}${zoom}/${x}/${y}.png?apikey=${apiKey}`
      return url
    },

    tileSize: new google.maps.Size(256, 256),
    maxZoom: MapDefaults.zoom.max,
    minZoom: MapDefaults.zoom.min,
    // @ts-ignore TODO 'radius' does not exist in type 'ImageMapTypeOptions'
    radius: 1738000,
    name: mapName,
  })

  map.mapTypes.set(mapName, thunderforestTiles)
  map.setMapTypeId(mapName)
}

const drawNavigationMarker = ({ map, maps, position }) => {
  return drawComponent({ map, maps, position, component: <NavigationMarker {...position} /> })
}

const useDrawNavigationMarker = ({ isMapLoaded, position, maps, map, enabled = true }: UseDrawNavigationMarkerProps) => {
  const markerRef = useRef<MapMarker>(null)

  onUpdate(() => {
    if (!isMapLoaded || !enabled) return

    if (!!markerRef.current) {
      markerRef.current.position = position
      return
    }

    const createNewMarker = async () => {
      markerRef.current = await drawNavigationMarker({ map, maps, position })
    }

    if (!!position) { createNewMarker() }
  }, [position, markerRef.current, isMapLoaded, enabled, maps, map])

  return { markerRef }
}

const useDrawCircles = ({ isMapLoaded, circles, maps, map, enabled = true }: UseDrawCircleProps) => {
  const circlesRef = useRef<({
    circle: MapCircle
    icon: MapMarker
  })[]>([])

  onUpdate(() => {
    if (!isMapLoaded || !enabled) return

    if (circlesRef.current?.length == circles.length) {
      circles.forEach((circle, index) => {
        circlesRef.current[index].circle.setCenter(circle.center)
        circlesRef.current[index].icon.setPosition(circle.center)
        circlesRef.current[index].circle.setRadius(circle.radius)
      })
      return
    }

    circlesRef.current = circles.map((c) => {
      const circle = drawCircle({ maps, map, radius: 1000, ...c })
      const icon = drawMarker({ maps, map, position: c.center, image: AppImages.X, zIndex: -1, size: 16 })

      return { circle, icon }
    })
  }, [circles, circlesRef.current, isMapLoaded, enabled, maps, map])

  return { circlesRef }

}

const drawCircle = ({ maps, center, ...rest }: DrawCircleProps) => {
  const circle = new maps.Circle({
    strokeColor: Theme.colors.light.primary3,
    strokeOpacity: 0.2,
    strokeWeight: 1,
    fillColor: Theme.colors.light.primary3,
    fillOpacity: 0.1,
    zIndex: -1,
    center,
    ...rest,
  })

  circle.setMap(maps)
  return circle
}

const getZoom = (map: MapInstance): number => {
  return map?.getZoom()
}

const getCenter = (map: MapInstance): ShortCoords => {
  return {
    lat: map?.center?.lat(),
    lng: map?.center?.lng(),
  }
}

const getPosition = (map: MapMarker): ShortCoords => {
  return {
    lat: map?.position?.lat(),
    lng: map?.position?.lng(),
  }
}

const animateTo = ({ map, maps, lat, lng, zoom = MapDefaults.zoom.default }: { map: MapInstance; maps: MapsInstance; zoom?: number } & ShortCoords) => {
  map.panTo(toMapLatLng({ maps, lat, lng }))
  map.setZoom(zoom)
}

const onMapUpdate = ({ isMapLoaded, map, maps, callback }, deps: any[] = []) => {
  onUpdate(() => {
    if (!isMapLoaded || !map || !maps) return
    callback?.()
  }, [...deps, map, isMapLoaded, maps, callback])
}

const drawNumberPin = async ({ position, total, ...rest }: DrawMapNumberPinProps) => {

  return drawComponent({
    position,
    zIndex: MapDefaults.zIndex.startingPoint,
    ...rest,
    component: <MapNumberPin total={total} />,
  })
}

type MapComponentProps<T> = {
  marker: MapMarker
  id: string | number
  props: T
}

const useRenderComponents = <T extends {
  position: ShortCoords
  id: MapComponentProps<T>['id']
}>
  ({
    data = [],
    renderItem,
    isMapLoaded,
    enabled = true,
    map,
    maps,
    onItemBlur,
    onItemHover,
    onItemPress }:
    UseRenderComponents<T>) => {
  const components = useRef<MapComponentProps<T>[]>([])

  const removeComponent = (c: MapComponentProps<T>) => {
    removeMarker(c.marker)
    components.current = components.current.filter(({ id }) => id !== c.id)
  }

  const renderComponent = async (item: T, index: number, preRenderedItem: ReactElement<any, string | JSXElementConstructor<any>>) => {

    return drawComponent({
      map,
      maps,
      position: item.position,
      component: preRenderedItem,
      zIndex: preRenderedItem?.props?.zIndex || null,
      onBlur: (event) => onItemBlur?.(item, { index, event }),
      onHover: (event) => onItemHover?.(item, { index, event }),
      onPress: (event) => onItemPress?.(item, { index, event }),
    })
  }

  const createComponent = async (item: T, index: number, preRenderedItem: ReactElement<any, string | JSXElementConstructor<any>>) => {
    const marker = await renderComponent(item, index, preRenderedItem)
    components.current.push({ id: item.id, marker, props: preRenderedItem.props })
  }

  const updateComponent = async (item: T, index: number, preRenderedItem: ReactElement<any, string | JSXElementConstructor<any>>) => {
    const component = components.current.find(({ id }) => id === item.id)

    if (!areaPropsEquals(component.props, preRenderedItem.props)) {
      removeMarker(component.marker)
      const updatedMarker = await renderComponent(item, index, preRenderedItem)
      component.marker = updatedMarker
      component.props = preRenderedItem.props
    }
  }

  onUpdate(() => {
    const isReady = !isMapLoaded || !map || !maps || !enabled || !data
    if (isReady) return

    const componentsToDelete: MapComponentProps<T>[] = []

    data?.forEach(async (item, index) => {
      const toAdd = !components.current?.some(({ id }) => id === item.id)
      const preRenderedItem = renderItem({ item, index })

      if (toAdd) {
        createComponent(item, index, preRenderedItem)
        return
      }

      updateComponent(item, index, preRenderedItem)
    })

    components.current?.forEach(component => {
      const { id } = component
      const prop = data?.find(c => id === c.id)

      if (!prop) { componentsToDelete.push(component) }
    })

    componentsToDelete.forEach(removeComponent)
  }, [
    data,
    renderItem,
    enabled,
    map,
    maps,
    isMapLoaded,
    components.current,
  ])
}

export const MapUtils = {
  useRenderComponents,
  onMapUpdate,
  getPosition,
  animateTo,
  getZoom,
  getCenter,
  getZoomByRadius,
  setPolylinesVisible,
  fitToMarkers,
  hideControls,
  followUser,
  drawComponent,
  toggleFullscreen,
  useMap,
  setThunderforestMapType,
  drawMarker,
  drawCircle,
  drawPolyline,
  drawNavigationMarker,
  drawDashedPolyline,
  removeMarker,
  removePolyline,
  useDrawCircles,
  useDrawNavigationMarker,
  removeElement,
  fitToCircle,
  removeElements,
  drawNumberPin,
}
