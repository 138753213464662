/* eslint-disable max-len */
import { api } from '@/app'
import { LeaderboardPosition, LeaderboardListFilters } from '@/types'
import {
  PaginationResponse,
  QueryManager,
  UsePaginationTypes,
} from '@codeleap/common'
import { queryClient } from './queryClient'

const BASE_URL = 'web_leaderboard/'

export async function list(
  params: LeaderboardListFilters & UsePaginationTypes.PaginationParams
) {
  const response = await api.get<PaginationResponse<LeaderboardPosition>>(
    BASE_URL,
    {
      params,
    }
  )

  return response.data
}

export const leaderboardManager = new QueryManager<LeaderboardPosition>({
  itemType: {} as LeaderboardPosition,
  name: 'leaderboard',
  queryClient: queryClient.client,

  listItems: async (limit, offset, params: LeaderboardListFilters) => {
    const response = await list({ limit, offset, ...params })
    return response
  },
})
