import './services/firebaseApp'

import { StyleProvider, ReactQuery } from '@codeleap/common'
import { Settings, variantProvider, React } from './app'
import { DebugModal, GlobalAlert, GlobalStyle, Onboarding, ShareModal, variants } from './components'
import { Provider } from 'react-redux'
import { store } from './redux'
import { APIClient } from './services'

const App = ({ children }) => {
  APIClient.Session.useSession(true)

  return (
    <>
      <GlobalStyle />
      {children}
      {/* <AppStatusOverlay /> */}
      {/* <GlobalAlert /> */}
      <DebugModal />
      {/* <Onboarding /> */}
      {/* <ShareModal /> */}
    </>
  )
}

export const Root = ({ children }) => {
  return (
    <ReactQuery.QueryClientProvider client={APIClient.queryClient.client}>
      <Provider store={store}>
        <StyleProvider
          settings={Settings}
          variantProvider={variantProvider}
          variants={variants}
          logger={logger}
        >
          <App>
            {children}
          </App>
        </StyleProvider>
      </Provider>
    </ReactQuery.QueryClientProvider>
  )
}
