import { LocalStorage, variantProvider } from '@/app'
import { View, Text, Button } from '@/components'
import { onMount } from '@codeleap/common'
import { CookieConsentBannerProps } from './types'
import { useCallback, useState } from 'react'
import { triggerGTMEvent } from '@/utils'

const DEFAULT_DELAY_TO_SHOW = 3000

export const CookieConsentBanner = (props: CookieConsentBannerProps) => {
  const [isClosing, setIsClosing] = useState(false)
  const [isVisible, setIsVisible] = useState(false)

  const delay = props?.delay || DEFAULT_DELAY_TO_SHOW

  onMount(() => {
    const timer = setTimeout(() => {
      setIsVisible(true)
    }, delay)

    return () => clearTimeout(timer)
  })

  const handleClose = useCallback(() => {
    setIsClosing(true)
    setTimeout(() => {
      props.onClose()
    }, 300)
  }, [props.onClose])

  const handleAccept = useCallback(() => {
    LocalStorage.setItem('COOKIE_CONSENT', true)
    triggerGTMEvent('cookie_consent_granted')
    handleClose()
  }, [handleClose])

  const handleReject = useCallback(() => {
    LocalStorage.setItem('COOKIE_CONSENT', false)
    triggerGTMEvent('cookie_consent_rejected')
    handleClose()
  }, [handleClose])

  return (
    <View css={[
      styles.wrapper,
      isVisible && styles.visible,
      isClosing && styles.closing,
      !isVisible && styles.hidden,
    ]}>
      <View css={styles.innerWrapper}>
        <Text text={'We use Cookies'} variants={['h5', 'bold']} />
        <Text text={'This website uses cookies to ensure you get the best experience on our website.'} />
        <View
          variants={['gap:2']}
          responsiveVariants={{
            mobile: ['gap:1', 'column'],
          }}
        >
          <Button debugName={'Accept Cookie Button'} text={'Accept All'} onClick={handleAccept} />
          <Button variants={['neutral2']} debugName={'Reject Cookie Button'} text={'Reject All'} onClick={handleReject} />
        </View>
      </View>
    </View>
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  wrapper: {
    position: 'fixed',
    left: '50%',
    transform: 'translate(-50%, 20%)',
    borderRadius: theme.borderRadius.medium,
    boxShadow: '0px 0px 16px 16px #00000020',
    backgroundColor: theme.colors.background,
    bottom: theme.spacing.value(2),
    zIndex: 8,
    transition: 'all 0.3s ease',
    opacity: 0,
    visibility: 'hidden',
    [theme.media.down('mobile')]: {
      width: '90%',
    },
  },
  innerWrapper: {
    padding: theme.spacing.value(2),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing.value(2),
  },
  visible: {
    opacity: 1,
    visibility: 'visible',
    transform: 'translate(-50%, 0)',
  },
  closing: {
    opacity: 0,
    visibility: 'hidden',
    transform: 'translate(-50%, 20%) scale(0.95)',
    pointerEvents: 'none',
  },
  hidden: {
    opacity: 0,
    visibility: 'hidden',
  },
}), true)
