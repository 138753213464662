import { React } from '@/app'
import { Event } from '@/types'
import { useCopyToClipboard } from '@/utils'
import { useBooleanToggle } from '@codeleap/common'
import { EmbedModal, ItemsModal, ItemsModalProps } from '../Modals'

export type EventShareModalProps = Omit<ItemsModalProps, 'items'> & {
  event: Event
}

export const EventShareModal = (props: EventShareModalProps) => {
  const { copied, copyToClipboard, setCopied } = useCopyToClipboard()
  const [embedModalVisible, toggleEmbedModal] = useBooleanToggle(false)

  const eventPath = `/events/${props.event?.id}`
  const siteUrl = process.env.GATSBY_SITE_URL.slice(0, -1)
  const pathname = `${siteUrl}${eventPath}`

  const iframeSource =
    `<iframe 
      style="position:relative; top:0; left:0; width:100%; min-height:700px; border:0;"
      src="${siteUrl}/runs/embed/${props.event?.id}" 
      frameborder="0" 
      allowfullscreen
    >
    </iframe>
  `

  const handleToggle = () => {
    setCopied(false)
    props.toggle?.()
  }

  return (
    // @ts-ignore
    <React.Fragment>
      <ItemsModal
        {...props}
        toggle={handleToggle}
        title='Share'
        items={[
          {
            text: copied ? 'Copied!' : 'Copy link',
            leftIcon: 'copy',
            rightIcon: copied ? 'check-circle' : null,
            onPress: () => copyToClipboard(pathname),
          }, {
            text: 'Embed',
            leftIcon: 'embed',
            onPress: toggleEmbedModal,
          }]}
      />

      <EmbedModal
        title='Embed'
        showBack
        iframeSource={iframeSource}
        visible={embedModalVisible}
        onBack={toggleEmbedModal}
        toggle={toggleEmbedModal}
      />
    </React.Fragment>
  )
}

