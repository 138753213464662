import { View, Text, Avatar } from '@/components'
import { EventComment } from '@/types'
import { getAbreviatedDate } from '@/utils'
import { PropsOf } from '@codeleap/common'

export type EventCommentRowProps = PropsOf<typeof View> & {
  item: EventComment
  noSeparator?: boolean
}

export const EventCommentRow = ({ item, noSeparator = false, ...rest }: EventCommentRowProps) => {
  const profile = item?.profile

  return (
    <View variants={['column', 'fullWidth']} css={{ maxWidth: 880 }} {...rest}>
      <View variants={['flex', 'marginBottom:1']}>
        <Avatar
          name={profile?.full_name}
          debugName='EventCommentRow Avatar'
          image={profile?.avatar}
          randomizeColor
          firstNameOnly={false}
        />
        <View variants={['column', 'marginLeft:2']}>
          <Text text={profile?.full_name} variants={['h4', 'color:neutral8']} className='truncate-1' />
          <Text text={getAbreviatedDate(item?.created_datetime)} variants={['color:neutral8']} />
        </View>
      </View>

      <Text text={item.comment} variants={['color:neutral8']} />

      {!noSeparator && (<View variants={['thinSeparator', 'marginTop:2']} />)}
    </View>
  )
}
