import { Theme, variantProvider } from '@/app'
import { View } from '@/components'
import { PropsOf } from '@codeleap/common'
import React, { CSSProperties } from 'react'

export type SkeletonProps = {
  rows?: number
  columns?: number
  gap?: number
  desktopStyle?: CSSProperties
  mobileStyle?: CSSProperties
  globalStyle?: CSSProperties
} & PropsOf<typeof View>

export const Skeleton = ({
  rows = 0,
  columns = 0,
  gap = Theme.spacing.value(1),
  desktopStyle,
  mobileStyle,
  globalStyle,
  style = {},
  ...rest }: SkeletonProps) => {
  const styles = variantProvider.createComponentStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.neutral3,
      borderRadius: theme.borderRadius.small,
      ...globalStyle,
      ...desktopStyle,

      [theme.media.down('mobile')]: {
        ...globalStyle,
        ...mobileStyle,
      },
    },
  }), true)

  const isListVertical = columns > 0 && rows === 0
  const isListHorizontal = rows > 0 && columns === 0

  return (
    <View
      {...rest}
      style={{
        gap,
        width: '100%',
        ...style,
        display: 'flex',
        flexDirection: isListVertical ? 'column' : isListHorizontal ? 'row' : 'row',
        flexWrap: isListVertical || isListHorizontal ? 'nowrap' : 'wrap',
      }}
    >
      {isListVertical &&
        Array.from({ length: columns }).map((_, index) => (
          <View
            key={index}
            style={styles.wrapper}
          />
        ))}

      {isListHorizontal &&
        Array.from({ length: rows }).map((_, index) => (
          <View key={index} style={styles.wrapper} />
        ))}

      {!isListVertical &&
        !isListHorizontal &&
        Array.from({ length: rows }).map((_, rowIndex) => (
          <React.Fragment key={rowIndex}>
            {Array.from({ length: columns }).map((_, colIndex) => (
              <View
                key={`${rowIndex}-${colIndex}`}
                style={styles.wrapper}
              />
            ))}
          </React.Fragment>
        ))}
    </View>
  )
}

