import { AppIcon, AppImages } from '@/app'
import { Icon } from '@/components'
import { LeaderboardPosition, LeaderboardSortOptions } from '@/types'
import { PropsOf } from '@codeleap/common'
import { Results } from './results'

function getTrophyByPosition(position: LeaderboardPosition['position']) {
  const icons = [
    AppImages.GoldTrophy,
    AppImages.SilverTrophy,
    AppImages.BronzeTrophy,
  ]
  if (!position || position > icons.length) return null
  return icons[position - 1]
}

function getSorbyByIcon(
  sortBy: LeaderboardSortOptions
): Pick<PropsOf<typeof Icon>, 'name' | 'variants'> {
  const iconName: Record<LeaderboardSortOptions, AppIcon> = {
    grading: 'award',
    total_distance: 'route',
    num_runs: 'running',
    num_courses: 'road',
    time_seconds: 'clock',
  }

  let variants: any = ['neutral5']

  if (sortBy === 'time_seconds') {
    variants = ['primary']
  }

  return {
    name: iconName[sortBy] || 'award',
    variants,
  }
}

export const getSortByText = (
  value: number,
  sortBy: LeaderboardSortOptions
) => {
  switch (sortBy) {
    case 'grading':
      return Results.formatGrade(value)
    case 'total_distance':
      return `${(value / 1000).toFixed(1).replace('-', '')} km`
    case 'time_seconds':
      return Results.formatResultsTime({ seconds: value })
    default:
      return value
  }
}

export const LeaderboardUtils = {
  getTrophyByPosition,
  getSorbyByIcon,
  getSortByText,
}
