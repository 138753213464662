import { AppImages, variantProvider } from '@/app'
import { ImageSlider, ImageSliderProps } from './ImageSlider'
import { Course, CourseMedia } from '@/types'
import { CourseUtils, MapUtils, useIsMobile, useIsTablet } from '@/utils'
import React, { useRef, useState } from 'react'
import { PropsOf, onUpdate, useBooleanToggle } from '@codeleap/common'
import { CourseMap, CourseMapProps } from './Courses'
import { Breadcrumbs, CenterWrapper, Image, VIDEO_PLAYER_ID, VideoPlayer, VideoPlayerRefProps, View } from '@/components'

type CourseBannerSliderProps = {
  course?: Course
  showMap?: boolean
  mapProps?: Omit<CourseMapProps, 'course'>
  imageSliderProps?: Omit<ImageSliderProps, 'images'>
  showBreadcrumbs?: boolean
  showVideo?: boolean
}

type SwipeDirection = 'left' | 'right'

const MAX_BANNER_HEIGHT = 500

export type BannerImageProps = PropsOf<typeof Image> & {
  map?: boolean
  video?: boolean
}

export const CourseBannerSlider = (props: CourseBannerSliderProps) => {

  const {
    course,
    showMap,
    showVideo = true,
    mapProps,
    imageSliderProps,
    showBreadcrumbs = false,
  } = props

  const isTablet = useIsTablet()
  const isMobile = useIsMobile()

  const [page, setPage] = useState(0)
  const [spotlightPage, setSpotlightPage] = useState(0)

  const [mapFullscreen, setMapFullscreen] = useBooleanToggle(false)
  const [spotlightVisible, setSpotlightVisible] = useBooleanToggle(false)

  const videoPlayerRef = useRef<VideoPlayerRefProps>(null)
  const hasVideo = !!course?.video_url
  const shoulShowVideo = showVideo && hasVideo
  const [isFullScreen, toggleFullScreen] = useBooleanToggle(false)

  const shouldShowMap = showMap && isTablet
  const videoIndex = shouldShowMap ? 1 : 0

  const showSpotlightButton = !spotlightVisible && shouldShowMap ? page !== undefined : true

  const onlyImageAdjustment = shoulShowVideo ? (shouldShowMap ? 2 : 1) : (shouldShowMap ? 1 : 0)

  const courseImages = React.useMemo(() => {
    const images: BannerImageProps[] = []
    const medias = course?.media as CourseMedia[]

    if (!medias?.length) {
      shouldShowMap && images.push({ map: true } as BannerImageProps)
      if (shoulShowVideo) images.push({ video: true } as BannerImageProps)
      images.push({ source: AppImages.CoursePhotoExample, alt: CourseUtils.getImageAlt(course) })
      return images
    }

    if (shouldShowMap) {
      images.push({ map: true } as BannerImageProps)
      if (shoulShowVideo) images.push({ video: true } as BannerImageProps)
    } else if (shoulShowVideo) images.push({ video: true } as BannerImageProps)

    medias?.forEach((image, index) => {
      images.push({ source: image?.file, alt: CourseUtils.getImageAlt(course, index + 1) })
    })

    return images
  }, [course?.media, shouldShowMap])

  const pictures = courseImages?.slice?.(onlyImageAdjustment)

  const onToggleModal = () => {
    if (shouldShowMap && page === 0) {
      MapUtils.toggleFullscreen()
      return
    }

    if (showVideo && hasVideo && page === videoIndex) {
      videoPlayerRef?.current?.toggleFullscreen(VIDEO_PLAYER_ID)
      return
    }
    if (!spotlightVisible) {
      const openningPage = page - onlyImageAdjustment

      setSpotlightPage(openningPage)
      setSpotlightVisible()
      return
    }

    setSpotlightVisible()
  }

  const isVideo = courseImages[page]?.video

  const handleSwipe = (direction: SwipeDirection) => {
    if (direction === 'left') {
      const toPage = page + 1 >= courseImages.length ? page : page + 1
      setPage(toPage)
    } else if (direction === 'right') {
      const toPage = page - 1 < 0 ? page : page - 1
      setPage(toPage)
    }
  }

  const renderItem = (image: BannerImageProps, index: number) => {
    const shouldShowMap = image?.map && isTablet
    const shouldShowVideo = image?.video

    if (shouldShowMap) {
      return (
        <CourseMap
          course={course}
          style={styles.map}
          expandable={false}
          draggable={mapFullscreen}
          onExpand={() => setMapFullscreen(true)}
          key={index}
          {...mapProps}
        />
      )
    }

    if (shouldShowVideo) {
      return (
        <VideoPlayer
          src={course?.video_url}
          style={{ height: bannerHeight, minHeight: IMAGE_HEIGHT, maxHeight: MAX_BANNER_HEIGHT }}
          //@ts-ignore
          ref={videoPlayerRef}
          isFullscreen={isFullScreen}
          setIsFullscreen={toggleFullScreen}
        />
      )
    }

    return (
      <Image
        {...image}
        css={styles.image}
        style={{
          height: bannerHeight,
        }}
        objectFit='cover'
        key={`${image.source}: ${index}`}
      />
    )

  }

  const bannerHeight = `calc(100vw - 50vw)`

  const Wrapper = isMobile ? View : CenterWrapper

  onUpdate(() => {
    if (!videoPlayerRef?.current) return
    const isPlayingVideo = videoPlayerRef?.current?.isPlaying
    if (isVideo) {
      isPlayingVideo ? videoPlayerRef?.current?.pause() : videoPlayerRef?.current?.play()
    } else {
      videoPlayerRef?.current?.pause()
    }
  }, [page])

  return (
    <Wrapper
      variants={['column']}
      responsiveVariants={{
        mobile: ['column-reverse'],
      }}
    >
      <View
        variants={['fullWidth']}
        style={{
          height: bannerHeight,
        }}
        css={styles.bannerInnerWrapper}
      >
        <ImageSlider
          images={courseImages}
          page={page}
          setPage={setPage}
          pagerProps={{
            onSwipe: (direction) => handleSwipe(direction as SwipeDirection),
            disableSwipe: isFullScreen,
            adaptiveHeight: false,
          }}
          showSpotlightComponent={showSpotlightButton}
          renderItem={renderItem}
          spotlightComponentProps={{
            modalProps: {
              pictures: pictures as any,
              page: spotlightPage,
              setPage: setSpotlightPage,
              visible: spotlightVisible,
              toggle: onToggleModal,
            },
          }}
          {...imageSliderProps}
        />
      </View>

      {showBreadcrumbs && (
        <Breadcrumbs
          variants={['marginTop:5']}
          responsiveVariants={{
            mobile: ['marginTop:2'],
          }}
        />)}

    </Wrapper>
  )
}

const IMAGE_HEIGHT = 400

const styles = variantProvider.createComponentStyle((theme) => ({
  closeIconWrapper: {
    ...theme.presets.absolute,
    ...theme.presets.justifyEnd,
    right: 0,
    zIndex: 9999,
  },
  bannerInnerWrapper: {
    minHeight: IMAGE_HEIGHT,
    maxHeight: MAX_BANNER_HEIGHT,
    overflow: 'hidden',
    [theme.media.up('mobile')]: {
      borderBottomRightRadius: theme.borderRadius.medium,
      borderBottomLeftRadius: theme.borderRadius.medium,
    },
  },
  image: {
    ...theme.presets.fullWidth,
    minHeight: IMAGE_HEIGHT,
    maxHeight: MAX_BANNER_HEIGHT,
  },
  map: {
    height: IMAGE_HEIGHT,
    width: '100%',
  },
  mapFullscreen: {
    height: '100vh',
    width: '100%',
  },
}), true)
