import { Run } from '@/types'
import { formatSeconds } from './date'
import { Time } from './time'

type formatResultsTimeProps = {
  seconds: number
  withoutHours?: boolean
  withoutSeconds?: boolean
}

const convertRunTimes = (params: formatResultsTimeProps) => {
  const { seconds: preciseSeconds, withoutHours } = params
  const shouldShowHours = withoutHours ? false : true
  const seconds = Math.round(preciseSeconds)
  return Time.formatSecondsIntoClockTime(seconds, shouldShowHours)
}

const formatResultsTime = (params: formatResultsTimeProps) => {
  const { seconds, withoutHours, withoutSeconds } = params
  const shouldShowHours = withoutHours ? false : true
  const shouldShowSeconds = withoutSeconds ? false : true
  const _seconds = Math.round(seconds)
  return formatSeconds(_seconds, shouldShowHours, shouldShowSeconds)
}

const formatGrade = (grading: Run['grading']) =>
  grading ? grading?.toFixed(1) : ' - -'

export const Results = {
  convertRunTimes,
  formatResultsTime,
  formatGrade,
}
