import { CookiesKeys, MapDefaults } from '@/app'
import { IPInfo, LongCoords } from '@/types'
import { createSlice } from '@codeleap/common'
import Cookies from 'js-cookie'

export type UserLocationState = LongCoords & { city?: string }

export type UserLocationRedux = {
  defaultLocation: UserLocationState
  location: UserLocationState
  ipInfo: IPInfo
}

const _location = Cookies.get(CookiesKeys.USER_LOCATION)
const _ipInfo = Cookies.get(CookiesKeys.IP_INFO)

const initialState: UserLocationRedux = {
  defaultLocation: { ...MapDefaults.center },
  location: _location ? JSON.parse(_location) : null,
  ipInfo: _ipInfo ? JSON.parse(_ipInfo) : null,
}

export const userLocationSlice = createSlice({
  name: 'UserLocation',
  initialState,
  reducers: {
    setLocation: (_, location: UserLocationState) => ({
      location,
    }),
    setIPInfo: (_, ipInfo: IPInfo) => ({ ipInfo }),
  },
  asyncReducers: {},
})
