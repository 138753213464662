import { api } from '@/app'
import { EventComment, Event } from '@/types'
import {
  PaginationResponse,
  QueryManager,
  UsePaginationTypes,
} from '@codeleap/common'
import { queryClient } from '../queryClient'

const BASE_URL = 'web_group_comment/'

type ListFilters = {
  group: Event['id']
}

export async function list(
  params: ListFilters & UsePaginationTypes.PaginationParams
) {
  const response = await api.get<PaginationResponse<EventComment>>(BASE_URL, {
    params,
  })

  return response.data
}

export const eventCommentsManager = new QueryManager<EventComment>({
  itemType: {} as EventComment,
  name: 'eventComments',
  queryClient: queryClient.client,

  listItems: async (limit, offset, params: ListFilters) => {
    const response = await list({ limit, offset, ...params })
    return response
  },
})
