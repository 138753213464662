import { ActivityIndicator, CourseCard, CourseCardProps, List, Scroll, SeeMoreList, Skeleton, SkeletonProps, View } from '@/components'
import { Course } from '@/types'
import { IconPlaceholder, PropsOf } from '@codeleap/common'
import { useCallback } from 'react'
import { onScrollToBottom } from '@/utils'
import { EmptyPlaceholder } from '@codeleap/web'
import { Theme } from '@/app'
import { COURSE_CARD_CONSTANTS } from '@/app/stylesheets/CourseCard'

export type CourseListProps = Partial<PropsOf<typeof List>> & {
  id?: string
  courses: Course[]
  cardVariants?: CourseCardProps['variants']
  onPress?: (course: Course) => void
  onHover?: (course: Course) => void
  onMouseLeave?: (course: Course) => void
  as?: 'scroll' | 'list'
  skeletonProps?: Partial<SkeletonProps>
}

export const CourseList = ({
  as = 'list',
  onMouseLeave,
  onHover,
  ListHeaderComponent,
  variants = [],
  courses,
  cardVariants = ['wide'],
  onPress,
  skeletonProps,
  ...rest }: CourseListProps) => {
  const isList = as === 'list'

  const renderItem = useCallback(({ item }) => {
    return (
      <CourseCard
        course={item}
        key={item.id}
        onHover={() => { onHover?.(item) }}
        onMouseLeave={() => onMouseLeave?.(item)}
        onPress={() => { onPress?.(item) }}
        variants={[...cardVariants, isList && 'fullWidth' as any]}
      />
    )
  }, [cardVariants, onPress, onHover, onMouseLeave, isList])

  if (rest?.isLoading) {
    return (
      <Skeleton
        columns={3}
        gap={Theme.spacing.value(2)}
        desktopStyle={{
          height: COURSE_CARD_CONSTANTS.DEFAULT.HEIGHT.DEFAULT,
          minWidth: isList ? '100%' : COURSE_CARD_CONSTANTS.DEFAULT.WIDTH.DEFAULT,
        }}
        mobileStyle={{
          height: COURSE_CARD_CONSTANTS.DEFAULT.HEIGHT.MOBILE,
          minWidth: COURSE_CARD_CONSTANTS.DEFAULT.WIDTH.MOBILE,
        }}
        style={{
          marginBottom: Theme.spacing.value(2),
          ...rest.style,
        }}
        {...skeletonProps}
      />
    )
  }

  if (!courses?.length) {
    return (
      <EmptyPlaceholder
        icon={'map-search' as IconPlaceholder}
        title='No courses'
        description='There are no courses on this location'
        style={rest.style}
        variants={['compact', 'icon:size4'] as any}
      />
    )
  }

  if (isList) {
    return (
      <SeeMoreList
        debugName='CourseList'
        data={courses as unknown as string[]}
        rowItemsSpacing={Theme.spacing.value(3)}
        renderItem={renderItem}
        layoutWrapperProps={{
          component: 'ol',
        }}
        {...rest} />
    )
  }

  return (
    <Scroll
      id={rest.id}
      style={rest.style}
      variants={[
        'column',
        'fullWidth',
        'paddingBottom:4',
        'fullHeight',
        ...variants as any[]]
      }
      onScroll={e => {
        if (!rest.hasNextPage) return
        onScrollToBottom(e, rest.fetchNextPage)
      }}>
      {ListHeaderComponent?.()}

      <View variants={['column', 'paddingHorizontal:2', 'gap:3']} component='ol'>
        {courses.map((course) => renderItem({ item: course }))}
      </View>

      {rest.isFetchingNextPage && (
        <View variants={['justifyCenter']}>
          <ActivityIndicator debugName='CourseList:Loading' />
        </View>)
      }
    </Scroll>
  )
}

