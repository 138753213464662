import { AppImages, variantProvider } from '@/app'
import { Touchable, View, Icon, Text, ActionIcon, VideoSpeedControl } from '@/components'
import { useBooleanToggle, useEffect, useRef } from '@codeleap/common'
import { PropsOf } from '@emotion/react'
import { forwardRef, useImperativeHandle } from 'react'
import screenfull from 'screenfull'

export const VIDEO_PLAYER_ID = 'video-player-wrapper'
export const VIDEO_PLAYER_VIDEO_TAG_ID = 'video-player-video-id'

type VideoPlayerProps = PropsOf<typeof View> & {
  src: string
  isFullscreen?: boolean
  setIsFullscreen?: (value?: boolean) => void
}

export type VideoPlayerRefProps = {
  play: () => void
  pause: () => void
  toggleFullscreen: (id: string) => void
  isPlaying: boolean
}

const VideoComponent = ({
  videoRef,
  isFullscreen,
  isPlaying,
  togglePlaying,
  src,
  hasInteractedRef,
  passedPlayTimeout
}) => {

  function handlePlay() {
    togglePlaying(true)
  }

  function handlePause() {
    togglePlaying(false)
  }

  function playVideo() {
    videoRef.current.play()
    togglePlaying(true)
  }

  useEffect(() => {
    const handleInteraction = () => {
      if (!hasInteractedRef.current) {
        hasInteractedRef.current = true
        if (!isPlaying && videoRef.current) {
          playVideo()
        }
      }
    }

    const eventsToListen = ['click', 'keydown', 'scroll']
    eventsToListen.forEach(event => window.addEventListener(event, handleInteraction))

    return () => {
      window.removeEventListener('click', handleInteraction)
      window.removeEventListener('keydown', handleInteraction)
    };
  }, [isPlaying])

  useEffect(() => {
    const autoplayTimeout = setTimeout(() => {
      if (!hasInteractedRef.current && videoRef.current && !isPlaying) {
        passedPlayTimeout.current = true
        playVideo()
      }
    }, 2000)

    return () => clearTimeout(autoplayTimeout)
  }, [isPlaying])


  return (
    <video
      ref={videoRef}
      style={{ ...styles.video, ...(isFullscreen ? { objectFit: 'contain' } : {}) }}
      onPlay={handlePlay}
      onPause={handlePause}
      muted
      playsInline
      id={VIDEO_PLAYER_VIDEO_TAG_ID}
      //@ts-ignore
      loading="lazy"
      poster={AppImages.CoursePhotoExample}
    >
      <source src={src} type='video/mp4' />
    </video>
  )
}

const VideoPlayerComponent = (props: VideoPlayerProps, ref: React.ForwardedRef<VideoPlayerRefProps>) => {
  const { style = {}, src } = props
  const [isPlaying, togglePlaying] = useBooleanToggle(false)
  const [overlayVisible, toggleOverlay] = useBooleanToggle(false)
  const videoRef = useRef<HTMLVideoElement>(null)
  const actionBtnText = isPlaying ? 'Pause' : 'Play'
  const showPlayButton = overlayVisible
  const hasInteractedRef = useRef(false)
  const passedPlayTimeout = useRef(false)
  const canPlay = hasInteractedRef.current || passedPlayTimeout.current

  const [internalIsFullscreen, setInternalIsFullscreen] = useBooleanToggle(false)
  const isFullscreen = props?.hasOwnProperty('isFullscreen') ? props?.isFullscreen : internalIsFullscreen
  const setIsFullscreen = (props?.hasOwnProperty('setIsFullscreen') ? props?.setIsFullscreen : setInternalIsFullscreen) as (value?: boolean) => void
  const showBottomControls = isFullscreen


  function toggleFullscreen(id: string) {
    const element = document.getElementById(id) as HTMLElement | null
    if (screenfull.isEnabled) {
      if (isFullscreen) {
        screenfull.exit()
      } else {
        if (element) {
          screenfull.request(element)
        }
      }
    } else {
      const videoElement = document.getElementById(VIDEO_PLAYER_VIDEO_TAG_ID) as HTMLVideoElement | null
      videoElement?.requestFullscreen()
    }
  }

  useImperativeHandle(ref, () => ({
    play: () => {
      if (!canPlay) return
      if (videoRef.current) {
        videoRef.current.play()
      } else {
        // Ugly fix to play the video after videoRef is fully rendered
        setTimeout(() => {
          videoRef.current?.play()
        }, 100)
      }
    },
    pause: () => {
      if (videoRef.current) {
        videoRef.current.pause()
      }
    },
    toggleFullscreen,
    isPlaying,
    isFullscreen,
  }))

  function onPressVideoArea() {
    toggleOverlay()
  }

  function onActionPress() {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play()
        toggleOverlay(false)
      } else {
        videoRef.current.pause()
        toggleOverlay(true)
      }
    }
  }

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(screenfull.isFullscreen)
    }

    if (screenfull.isEnabled) {
      screenfull.on('change', handleFullscreenChange)
    }

    return () => {
      if (screenfull.isEnabled) {
        screenfull.off('change', handleFullscreenChange)
      }
    }
  }, [setIsFullscreen])



  return (
    <Touchable
      id={VIDEO_PLAYER_ID}
      style={{ ...styles.wrapper, ...style }}
      debugName='video player area'
      onPress={onPressVideoArea}
    >

      <Touchable
        debugName='video player button'
        onClick={(e) => {
          e.stopPropagation()
          onActionPress()
        }}
        variants={[!showPlayButton && 'hidden']}
      >
        <View
          variants={['paddingHorizontal:2', 'paddingVertical:1.75', 'gap:0.5', 'alignCenter']}
          style={{
            ...styles.fakeBtnWrapper,
            opacity: showPlayButton ? 1 : 0,
          }}
        >
          <Icon
            debugName='Rating Star'
            variants={['size:2']}
            name={!isPlaying ? 'play-circle' : 'pause-circle'}
          />
          <Text
            text={actionBtnText}
            style={styles.fakeBtnText}
          />
        </View>
      </Touchable>

      {showBottomControls ? (
        <View
          variants={['gap:3', 'alignEnd']}
          style={styles.controls} onClick={(e) => e.stopPropagation()}
        >
          <VideoSpeedControl videoRef={videoRef} />

          <ActionIcon
            icon='minimize-2'
            debugName='Maximize Course Photos Button'
            variants={['backgroundColor:neutral2', 'iconSize:3', 'padding:2', 'border-radius:rounded', 'imageSlider', 'largeish']}
            responsiveVariants={{
              mobile: ['backgroundColor:neutral2', 'iconSize:3', 'paddingHorizontal:0.5', 'border-radius:smallish', 'imageSlider']
            }}
            style={styles.minimizeButton}
            onClick={(e) => {
              e.stopPropagation()
              toggleFullscreen(VIDEO_PLAYER_ID)
            }}
          />
        </View>
      ) : null}

      <View
        style={{
          ...styles.overlay,
          opacity: overlayVisible ? 1 : 0,
        }}
      />

      <VideoComponent
        isFullscreen={isFullscreen}
        src={src}
        togglePlaying={togglePlaying}
        videoRef={videoRef}
        hasInteractedRef={hasInteractedRef}
        passedPlayTimeout={passedPlayTimeout}
        isPlaying={isPlaying}
      />
    </Touchable>
  )
}

//@ts-ignore
export const VideoPlayer: (props: VideoPlayerProps) => ReactJSXElement = forwardRef(VideoPlayerComponent)

const styles = variantProvider.createComponentStyle((theme) => ({
  wrapper: {
    ...theme.presets.fullWidth,
    ...theme.presets.fullHeight,
    ...theme.presets.relative,
    ...theme.presets.center,
  },
  playBtn: {
    zIndex: 2,
    backgroundColor: theme.colors.neutral9,
    borderRadius: theme.borderRadius.small,
  },
  video: {
    ...theme.presets.absolute,
    ...theme.presets.fullWidth,
    ...theme.presets.fullHeight,
    objectFit: 'cover',
  },
  overlay: {
    ...theme.presets.full,
    ...theme.presets.absolute,
    ...theme.presets.center,
    background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.6))',
    zIndex: 1,
    transition: 'opacity 0.3s ease',
  },
  fakeBtnWrapper: {
    backgroundColor: theme.colors.neutral9,
    borderRadius: theme.borderRadius.small,
    cursor: 'pointer',
    zIndex: 2,
    transition: 'opacity 0.3s ease',
  },
  fakeBtnText: {
    color: theme.colors.neutral1,
  },
  fakeBtnIcon: {
    color: theme.colors.neutral1,
  },
  controls: {
    zIndex: 2,
    ...theme.presets.absolute,
    bottom: theme.spacing.value(5),
    right: theme.spacing.value(5),

    [theme.media.down('mobile')]: {
      bottom: theme.spacing.value(2),
      right: theme.spacing.value(2),
      ...theme.presets.column,
      ...theme.spacing.gap(2),
    },
  },
  minimizeButton: {
    [theme.media.down('mobile')]: {
      borderRadius: theme.borderRadius.smallish,
    },
  },
  speedControl: {
    [theme.media.down('mobile')]: {
      borderRadius: theme.borderRadius.smallish,
    },
  },
}), true)
