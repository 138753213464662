import { AppImages, variantProvider } from '@/app'
import { ReactNode } from 'react'
import { Text, Image, View } from '@/components'
import { getImageAlt } from '../misc'
import { StaticImage } from 'gatsby-plugin-image'

type FeatureItemProps = {
  children: ReactNode
}

const TITLE = 'Skamper Features'

const LargeItem = ({ index, image, title, topImage = false, firstSlide = false }) => {

  return (
    <View
      variants={['row', 'justifySpaceBetween', 'gap:7.5', 'paddingHorizontal:5']}
      responsiveVariants={{
        tabletSmall: ['gap:4'],
      }}
    >
      <Image
        alt={getImageAlt(TITLE, index + 1)}
        source={image}
        objectFit='cover'
        variants={['alignSelfStart', topImage ? null : 'marginTop:auto']}
        style={styles.largeItemImage}
      />

      <View
        variants={['fullHeight', 'fullWidth', 'center']}
        style={styles.largeTextWrapper}
      >
        <Text
          text={title}
          variants={[firstSlide ? 'h0' : 'h1', 'color:neutral1', 'textLeft']}
          responsiveVariants={{
            tabletSmall: [firstSlide ? 'h1' : 'h2', 'color:neutral1'],
          }}
          component='h2'
        />
      </View>
    </View>
  )
}

export const useFeaturesItems = () => {

  const items: Record<string, FeatureItemProps> = {
    runs: {
      children: (
        <View
          variants={['column', 'justifySpaceBetween', 'padding:2', 'paddingBottom:0', 'relative', 'fullWidth']}
          style={styles.itemInnerWrapper}
        >
          <Text
            text='Find the best runs wherever you are.'
            variants={['h1', 'color:neutral1']}
            style={styles.itemTitle}
            component='h2'
          />

          {/* @ts-ignore */}
          <StaticImage
            src={'../../app/assets/images/home-screen.webp'}
            alt={getImageAlt(TITLE, 1)}
            style={{ ...styles.itemImage, ...styles.runsImage }}
            placeholder={'blurred'}
            blurredOptions={{
              toFormat: 'webp'
            }}
          />
        </View>
      ),
    },
    free: {
      children: (
        <View
          variants={['column', 'justifySpaceBetween', 'padding:2', 'paddingBottom:0', 'relative', 'fullWidth']}
          style={styles.itemInnerWrapper}
        >
          <Text
            text='Completely free - full access to runs and features'
            variants={['h2', 'color:neutral1']}
          />

          {/* @ts-ignore */}
          <StaticImage
            src={'../../app/assets/images/home-sliced.webp'}
            alt={getImageAlt(TITLE, 2)}
            objectFit='cover'
            style={{ ...styles.itemImage, ...styles.homeSliced }}
            placeholder={'blurred'}
            blurredOptions={{
              toFormat: 'webp'
            }}
          />

          {/* @ts-ignore */}
          <StaticImage
            src={'../../app/assets/images/explore-screen.webp'}
            alt={getImageAlt(TITLE, 3)}
            objectFit='cover'
            style={{ ...styles.itemImage, ...styles.exploreImage }}
            placeholder={'blurred'}
            blurredOptions={{
              toFormat: 'webp'
            }}
          />
        </View>
      ),
    },
    stats: {
      children: (
        <View
          variants={['column', 'justifySpaceBetween', 'padding:2', 'paddingBottom:0']}
          style={styles.itemInnerWrapper}
        >
          <Text
            text='See detailed stats for your run before you go.'
            variants={['h2', 'color:neutral1']}
          />

          {/* @ts-ignore */}
          <StaticImage
            src={'../../app/assets/images/details-screen.webp'}
            alt={getImageAlt(TITLE, 4)}
            objectFit='cover'
            style={{ ...styles.itemImage, ...styles.detailsImage }}
            placeholder={'blurred'}
            blurredOptions={{
              toFormat: 'webp'
            }}
          />
        </View>
      ),
    },
    follow: {
      children: (
        <View
          variants={['column', 'justifySpaceBetween', 'padding:2', 'paddingTop:0']}
          style={styles.itemInnerWrapper}
        >
          {/* @ts-ignore */}
          <StaticImage
            src={'../../app/assets/images/run-screen.webp'}
            alt={getImageAlt(TITLE, 5)}
            objectFit='cover'
            style={{ ...styles.itemImage, ...styles.topImage, ...styles.runImage }}
            placeholder={'blurred'}
            blurredOptions={{
              toFormat: 'webp'
            }}
          />
          <Text
            text='Follow every run with confidence'
            variants={['h2', 'color:neutral1']}
          />
        </View>
      ),
    },
    alerts: {
      children: (
        <View
          variants={['column', 'justifySpaceBetween', 'padding:2', 'paddingTop:0', 'relative']}
          style={styles.itemInnerWrapper}
        >
          {/* @ts-ignore */}
          <StaticImage
            src={'../../app/assets/images/notifications-screen.webp'}
            alt={getImageAlt(TITLE, 6)}
            objectFit='cover'
            style={{ ...styles.itemImage, ...styles.topImage, ...styles.notificationsImage }}
            placeholder={'blurred'}
            blurredOptions={{
              toFormat: 'webp'
            }}
          />
          <Text
            text='Run safely with friend alerts'
            variants={['h2', 'color:neutral1']}
          />
        </View>
      ),
    },
    friends: {
      children: (
        <View
          variants={['column', 'justifySpaceBetween', 'padding:2', 'paddingBottom:0']}
          style={styles.itemInnerWrapper}
        >
          <Text
            text='Compete with friends and other runners'
            variants={['h2', 'color:neutral1']}
          />

          {/* @ts-ignore */}
          <StaticImage
            src={'../../app/assets/images/leaderboard-screen.webp'}
            alt={getImageAlt(TITLE, 7)}
            objectFit='cover'
            style={{ ...styles.itemImage, ...styles.leaderboardImage }}
            placeholder={'blurred'}
            blurredOptions={{
              toFormat: 'webp'
            }}
          />
        </View>
      ),
    },
    milestone: {
      children: (
        <View
          variants={['column', 'justifySpaceBetween', 'padding:2', 'paddingBottom:0']}
          style={styles.itemInnerWrapper}
        >
          <Text
            text='Celebrate progress and milestones'
            variants={['h2', 'color:neutral1']}
          />

          {/* @ts-ignore */}
          <StaticImage
            src={'../../app/assets/images/milestone-screen.webp'}
            alt={getImageAlt(TITLE, 8)}
            objectFit='cover'
            style={{ ...styles.itemImage, ...styles.milestoneImage }}
            placeholder={'blurred'}
            blurredOptions={{
              toFormat: 'webp'
            }}
          />
        </View>
      ),
    },
    insights: {
      children: (
        <View
          variants={['column', 'justifySpaceBetween', 'padding:2', 'paddingBottom:0']}
          style={styles.itemInnerWrapper}
        >
          <Text
            text='Stay motivated with personal insights'
            variants={['h2', 'color:neutral1']}
          />

          {/* @ts-ignore */}
          <StaticImage
            src={'../../app/assets/images/dashboard-screen.webp'}
            alt={getImageAlt(TITLE, 9)}
            objectFit='cover'
            style={{ ...styles.itemImage, ...styles.dashboardImage }}
            placeholder={'blurred'}
            blurredOptions={{
              toFormat: 'webp'
            }}
          />
        </View>
      ),
    },
  }

  const largeItems: Record<string, FeatureItemProps> = {
    runs: {
      children: LargeItem({
        index: 0,
        image: AppImages.HomeScreen2,
        title: 'Find the best runs wherever you are',
        firstSlide: true,
      }),
    },
    free: {
      children: LargeItem({
        index: 1,
        image: AppImages.ExploreScreen,
        title: 'Completely free - full access to runs and features',
      }),
    },
    stats: {
      children: LargeItem({
        index: 2,
        image: AppImages.DetailsScreen,
        title: 'See detailed stats for your run before you go',
      }),
    },
    follow: {
      children: LargeItem({
        index: 3,
        image: AppImages.RunScreen,
        title: 'Follow every run with confidence',
        topImage: true,
      }),
    },
    alerts: {
      children: LargeItem({
        index: 4,
        image: AppImages.NotificationsScreen,
        title: 'Run safely with friend alerts',
        topImage: true,
      }),
    },
    friends: {
      children: LargeItem({
        index: 5,
        image: AppImages.LeaderboardScreen,
        title: 'Compete with friends and other runners',
      }),
    },
    milestone: {
      children: LargeItem({
        index: 6,
        image: AppImages.ResultsScreen,
        title: 'Celebrate progress and milestones',
      }),
    },
    insights: {
      children: LargeItem({
        index: 7,
        image: AppImages.DashboardScreen,
        title: 'Stay motivated with personal insights',
      }),
    },
  }

  return {
    items,
    largeItems,
  }
}

const ITEM_HEIGHT = 400
const ITEM_IMAGE_WIDTH = 190
const NOTIFICATION_IMAGE_WIDTH = 275.26
const ITEM_TITLE_IMAGE = 178

export const FEATURE_SECTION_IMAGE_WIDTH = 218

const styles = variantProvider.createComponentStyle(theme => ({
  wrapper: {
  },
  item: {
    height: ITEM_HEIGHT,
    overflow: 'hidden',
  },
  itemInnerWrapper: {
    zIndex: 1,
  },
  itemTitle: {
    ...theme.presets.fullWidth,
    maxWidth: ITEM_TITLE_IMAGE,
  },
  itemImage: {
    maxWidth: '40%',
    minWidth: ITEM_IMAGE_WIDTH,
    ...theme.presets.fullWidth,
  },
  topImage: {
    maxWidth: 195,
  },
  notificationImage: {
    top: 171,
    maxWidth: NOTIFICATION_IMAGE_WIDTH,
  },
  milestoneImage: {
    minWidth: 170,
    marginTop: 'auto',
    alignSelf: 'center'
  },
  dashboardImage: {
    marginTop: 'auto',
    alignSelf: 'center'
  },
  runsImage: {
    minWidth: 200,
    maxWidth: '80%',
    ...theme.presets.absolute,
    ...theme.presets.right,
    top: theme.spacing.value(12),

  },
  homeSliced: {
    minWidth: 68,
    width: '27%',
    ...theme.presets.absolute,
    ...theme.presets.left,
    top: theme.spacing.value(12),
    marginTop: 'auto'
  },
  exploreImage: {
    ...theme.spacing.marginLeft(8.5),
    marginTop: 'auto',
    alignSelf: 'center'
  },
  detailsImage: {
    marginTop: 'auto',
    alignSelf: 'center'
  },
  runImage: {
    alignSelf: 'center'
  },
  notificationsImage: {
    alignSelf: 'center'
  },
  leaderboardImage: {
    marginTop: 'auto',
    alignSelf: 'center'
  },
  //Large Style
  largeItemImage: {
    maxWidth: FEATURE_SECTION_IMAGE_WIDTH,
    ...theme.presets.fullWidth,
  },
}), true)
